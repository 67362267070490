import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { AuthService } from "../../auth/AuthService";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {
  username: string;
  password: string;

  constructor(
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {

  }

  onUsernameChange(value: string): void {
    this.username = value;
  }

  onPasswordChange(value: string): void {
    this.password = value;
  }

  async btnLoginClicked() {
    this.spinner.show();
    await this.authService.SignIn(this.username, this.password);
  }
}
