import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AMZCheckoutModel } from "../models/AMZCheckoutModel";
import { RootSongRequestModel } from "../models/SongRequestModel";
import { webService } from "../webServices/api";
import { RootConfigModel } from "../models/ConfigModel";
import { RequestedModel } from "../models/RequestedModel";
import { RootPartyModel } from "../models/PartyModel";
import { RootPlaylistModel } from "../models/PlaylistModel";
import { RootSongModel } from "../models/SongModel";

@Injectable()
export class WebService {
  constructor(private http: HttpClient) { }

  async GetSongRequestByStandard(currentPlaceId: string, addressName: string): Promise<RootSongRequestModel> {
    const param = "?placeId=" + currentPlaceId + "&businessName=" + addressName;
    const root = await this.http.get<RootSongRequestModel>(webService.GetSongRequestByStandard + param).toPromise();
    return root;
  }

  async GetPartyById(id: string): Promise<RootPartyModel> {
    const param = "?id=" + id;
    const root = await this.http
      .get<RootPartyModel>(webService.GetPartyById + param)
      .toPromise();
    return root;
  }

  async GetPartyPlaylist(id: string): Promise<RootPlaylistModel> {
    const param = "?id=" + id;
    const root = await this.http
      .get<RootPlaylistModel>(webService.GetPartyPlaylist + param)
      .toPromise();
    return root;
  }

  async GetPartyPlaylistDetail(id: string, playlistKey: string): Promise<RootSongModel> {
    const param = "?id=" + id + "&playlistKey=" + playlistKey;
    const root = await this.http
      .get<RootSongModel>(webService.GetPartyPlaylistDetail + param)
      .toPromise();
    return root;
  }

  async requestStandardSong(firstName: string,
    lastName: string,
    songKey: string,
    songName: string,
    songArtist: string,
    songThumb: string,
    duration_ms: string,
    explicit: string,
    packageName: string,
    packageId: string,
    requestValue: string,
    requestType: string,
    latitude: string,
    longitude: string,
    addressName: string,
    address: string,
    currentPlaceId: string,
    isPremium: string,
    uuid: string): Promise<RequestedModel> {

    const params = new HttpParams()
      .set("firstName", firstName)
      .set("lastName", lastName)
      .set("songKey", songKey)
      .set("songName", songName)
      .set("songArtist", songArtist)
      .set("songThumb", songThumb)
      .set("duration_ms", duration_ms)
      .set("explicit", explicit)
      .set("packageName", packageName)
      .set("packageId", packageId)
      .set("requestValue", requestValue)
      .set("requestType", requestType)
      .set("latitude", latitude)
      .set("longitude", longitude)
      .set("businessName", addressName)
      .set("address", address)
      .set("placeId", currentPlaceId)
      .set("isPremium", isPremium)
      .set("uuid", uuid);

    const root = await this.http
      .post<RequestedModel>(webService.requestStandardSong, params).toPromise();
    return root;
  }

  async getConfig(): Promise<RootConfigModel> {
    const root = await this.http.get<RootConfigModel>(webService.GetConfig).toPromise();
    return root;
  }

  async getSongRequestByEvent(event: string): Promise<RootSongRequestModel> {
    const param = "?eventKey=" + event;
    const root = await this.http
      .get<RootSongRequestModel>(webService.GetSongRequestByKaraokeEvent + param)
      .toPromise();
    return root;
  }

  async CreateCheckoutSessionAMZ(
    key: string,
    amount: number
  ): Promise<AMZCheckoutModel> {
    const amzAmount = (amount / 100).toString();
    const params = new HttpParams()
      .set("currentPageUrl", window.location.origin + "/?key=" + key)
      .set("amount", amzAmount);

    localStorage.setItem("CURRENT_AMOUNT", amzAmount);

    const root = await this.http
      .post<AMZCheckoutModel>(webService.CreateCheckoutSessionAMZ, params)
      .toPromise();

    return root;
  }
}
