import {
  AfterViewInit,
  Component,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { HttpClient, HttpParams } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from "@angular/router";
import { AnimationOptions } from "ngx-lottie";
import { ModalDirective } from "ngx-bootstrap/modal";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AngularFireAnalytics } from "@angular/fire/analytics";
import { Location } from "@angular/common";
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import { AngularFirestore } from "@angular/fire/firestore";
import { shareReplay } from "rxjs/operators";
import { TipComponent } from "../../base/tip.component";
import { TipDJModalComponent } from "../../modal/tipDJModal/TipDJModal.component";
import { EventModel, RootEventModel } from "../../../models/EventModel";
import { RootSongModel, SongModel } from "../../../models/SongModel";
import { SpotifyService } from "../../../services/SpotifyService";
import { AuthService } from "../../../auth/AuthService";
import { AppService } from "../../../services/app.service";
import { Constants } from "../../../helpers/Constants";
import { Helpers } from "../../../helpers/Helpers";
import { webService } from "../../../webServices/api";
import { CommonModel } from "../../../models/CommonModel";
import { AMZUpdateCheckoutModel } from "../../../models/AMZUpdateCheckout";
import { EventDetailsModel } from "../../../models/EventDetailModel";
import { PlaylistModel } from "../../../models/PlaylistModel";
import { SpotifyTrackModel } from "../../../models/SpotifyTrackModel";
import { HelpModalComponent } from "../../modal/helpModal/helpModal.component";
import { WebService } from "../../../webServices/WebService";
import { PartyPremiumRequestModalComponent } from "../../modal/partyPremiumRequestModal/partyPremiumRequestModal.component";
import { UpvoteModalComponent } from "../../modal/upvoteModal/upvoteModal.component";

declare var $: any;
declare var window: any;

@Component({
  templateUrl: "party.component.html",
  providers: [
    { provide: CarouselConfig, useValue: { interval: 5000, noPause: false, showIndicators: true } }
  ]
})
export class PartyComponent
  extends TipComponent
  implements OnInit, AfterViewInit {

  @ViewChild("usernameModal") public usernameModal: ModalDirective;
  @ViewChild("checkingRequestModal") public checkingRequestModal: ModalDirective;
  @ViewChild("standardRequestModal") public standardRequestModal: ModalDirective;
  @ViewChild("askNumberModal") public askNumberModal: ModalDirective;
  @ViewChild("menuModal") public menuModal: ModalDirective;
  @ViewChild("introModal") public introModal: ModalDirective;
  @ViewChild("radiusModal") public radiusModal: ModalDirective;
  @ViewChild("firstUseModal") public firstUseModal: ModalDirective;
  @ViewChild("currentPlaylistModal") public currentPlaylistModal: ModalDirective;
  @ViewChild("qrCodeModal") public qrCodeModal: ModalDirective;
  @ViewChild(UpvoteModalComponent) upvoteModalComponent: UpvoteModalComponent;
  @ViewChild(TipDJModalComponent) tipDJModalComponent: TipDJModalComponent;
  @ViewChild(HelpModalComponent) helpModalComponent: HelpModalComponent;
  @ViewChild(PartyPremiumRequestModalComponent) partyPremiumRequestModalComponent: PartyPremiumRequestModalComponent;

  @Input() showSpinner = true;

  uuid: string;
  party: string;
  partyName: string;

  key: string;
  playlists = [];
  searchSongs = [];
  event: EventModel;
  number: string;

  currentSongSelected: SongModel;
  currentSongThumb: string;
  currentSongTitle: string;
  currentSongKey: string;
  duration_ms: string;
  explicit: string;
  currentAppleMusic: string;
  currentSongArtist: string;
  currentRequestedBy: string;

  amazonCheckoutSessionId: string;

  shoutoutMessage = "";
  shoutoutPlaceholder =
    "Type your message. \nEx. I’d like to give a shoutout to ";
  requestType = "1";
  countSongRequest = 0;
  unlockGiftDes = "Only 5 More Requests";

  findLocationTitle = "Find Nearby DJ Session";
  findLocationDes =
    "Tell us your current location or a location near the session you’re looking for.";

  currentRequestedKey = "";

  notifyTitle = "";

  hasNoData = false;
  noLocation = false;
  isKaraoke = false;
  djUid = "";
  payloadJSON: string;
  signature: string;
  events = [];

  djName: string;
  profileUrl: string;
  hasSponsor: boolean;

  defaultImage = "../../assets/images/image01.png";

  config = {
    img_item_01: "",
    img_item_02: "",
    img_item_03: "",
    item: "",
    img_reward_01: "",
    img_reward_02: "",
    img_reward_03: "",
    hasSponsor: "",
  };

  searchKeyword: string;
  shareLink: string;
  isRunning = false;
  progressValue = 3;
  options: AnimationOptions = {
    path: "/assets/megaphone.json",
  };
  tickerOptions: AnimationOptions = {
    path: "/assets/ticker.json",
  };
  spotifyOptions: AnimationOptions = {
    path: "/assets/spotify.json",
  };
  loadingTemplate = `<ng-lottie [options]="optionSpinner" width="200px" height="100px"></ng-lottie>`;
  phoneTypeFormGroup: FormGroup;
  @Input() phoneType: string;
  selectedCountryCode = "us";
  phoneCode = "+1";
  countryCodes = ["us", "ca", "vn"];

  btnShoutoutClickedEvent: any;
  btnTipClickedEvent: any;
  btnMenuClickedEvent: any;
  getSpotifyPlaylistEvent: any;

  playlistCountdown = "The Next Playlist Starts In 20 mins 00 secs";
  playlistCountdownTimeOnly = " 20 mins 00 secs";
  timeinterval: any;

  optionUpdatingPlaylist: AnimationOptions = {
    path: '/assets/updatingPlaylist.json'
  };
  optionSpinner: AnimationOptions = {
    path: '/assets/loading.json'
  };
  optionCreateParty: AnimationOptions = {
    path: '/assets/createParty.json'
  };

  isUpdatingPlaylist = false;
  collectionSubscribe: any;

  constructor(
    public analytics: AngularFireAnalytics,
    public router: Router,
    public spotify: SpotifyService,
    public route: ActivatedRoute,
    public http: HttpClient,
    public webService: WebService,
    public toastr: ToastrService,
    public spinner: NgxSpinnerService,
    public authAppService: AuthService,
    public fb: FormBuilder,
    public appService: AppService,
    public location: Location,
    public firestore: AngularFirestore,
  ) {
    super(router, route, http, webService, toastr, spinner, authAppService,
      appService,
      location
    );

    this.registerEvent();

    this.checkLightDarkMode();

    this.handleRoute();
  }

  ngOnInit(): void {
    this.initForm();
    this.getConfig();
    this.playlists = [];
    this.songs = [];
    this.events = [];
    this.shareLink = "https://ujay.com" + this.router.url;
    this.analytics.logEvent("Web_OpenPage");
  }

  ngAfterViewInit(): void {
    this.checkFirstTime();
    this.handlePayment();
    this.handleModal();
    this.timeinterval = setInterval(this.updateClock, 1000);
  }

  initStandardSession() {
    this.appService.IsTipping = false;
    this.appService.IsShoutout = false;
    this.isKaraoke = true;
  }

  onDestroy(): void {
    this.btnShoutoutClickedEvent.unsubscribe();
    this.btnTipClickedEvent.unsubscribe();
  }

  handleRoute() {
    this.route.queryParams.subscribe((params) => {
      this.key = params["key"];
      this.uuid = params["dj"];
      if (this.key !== undefined) {
        this.sessionType = 1;
        this.appService.IsShoutout = true;
        this.getSessionDetail(this.key);
      } else if (this.uuid !== undefined) {
        this.sessionType = 1;
        this.appService.IsShoutout = true;
        this.getSessionByUser(this.uuid, true);
      } else {
        this.sessionType = 3;
        this.initStandardSession();
        this.party = params["p"];
        if (this.party !== undefined) {
          localStorage.setItem(Constants.SETTINGS_PARTY_ID, this.party);
        } else {
          localStorage.setItem(Constants.SETTINGS_PARTY_ID, "");
        }
      }

      this.number = params["n"];
      if (this.number !== undefined) {
        localStorage.setItem(Constants.SETTINGS_NUMBER, this.number);
      } else {
        this.number = localStorage.getItem(Constants.SETTINGS_NUMBER);
      }
      this.amazonCheckoutSessionId = params["amazonCheckoutSessionId"];
      if (this.amazonCheckoutSessionId !== undefined) {
        localStorage.setItem(
          "amazonCheckoutSessionId",
          this.amazonCheckoutSessionId
        );
        if (this.requestType == "2") {
          this.tipDJ();
        } else {
          this.updateRequestASong();
        }
      }
    });
  }

  registerEvent() {
    this.btnShoutoutClickedEvent =
      this.appService.btnShoutoutClicked.subscribe((event) => {
        this.btnShoutoutClicked();
      });

    this.btnTipClickedEvent =
      this.appService.btnTipClicked.subscribe((event) => {
        this.btnTipClicked();
      });

    this.btnMenuClickedEvent =
      this.appService.btnMenuClicked.subscribe((event) => {
        this.menuModal.show();
      });

    this.btnWrongVenueClickedEvent =
      this.appService.btnWrongVenueClicked.subscribe((event) => {
        this.btnWrongVenueClicked();
      });

    this.getSpotifyPlaylistEvent =
      this.appService.getSpotifyPlaylist.subscribe((event) => {
        this.GetSpotifyPlaylistAndGoTo();
      });

    window.addEventListener("message", async (event) => {
      if (event.data.toString().includes("mins")) {
        this.playlistCountdown = "The Next Playlist Starts In " + event.data;
        this.playlistCountdownTimeOnly = event.data;
      }
      if (event.data.toString().includes("REFRESH_TICKER")) {
        console.log("REFRESH_TICKER");
        this.isUpdatingPlaylist = true;
      }
    });
  }

  checkLightDarkMode() {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      document.body.classList.toggle('dark-theme');
    }

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
      const newColorScheme = event.matches ? "dark" : "light";
      if (newColorScheme == 'dark') {
        document.body.classList.toggle('dark-theme');
      } else {
        document.body.classList.toggle('dark-theme');
      }
    });
  }

  async refreshPage() {
    if (this.collectionSubscribe) {
      this.collectionSubscribe.unsubscribe();
    }
    this.collectionSubscribe = this.firestore.collection(`tb_spotify_playlist`)
      .doc(this.spotifyPlaylistKey)
      .snapshotChanges()
      .pipe(shareReplay())
      .subscribe(data => {
        var changedData = data as any;
        var payload = changedData.payload.data();
        if (data.type == "modified") {
          if (payload.lastUpdated == this.lastUpdated) {
            this.isUpdatingPlaylist = false;
          }
          const found = this.songs.find((obj) => {
            return obj.key === payload.key;
          });
          if (!found) {
            if (this.songs.length > 0) {
              this.isEmptyPlaylist = false;
            }
            if (this.sessionType == 3) {
              // this.GetSpotifyPlaylist();
              // this.GetSongRequestByStandard(false);
            }
          }
        }
      });
  }

  btnWrongVenueClicked() {
    this.addressName = "";
    if (this.sessionType == 3) {
      this.getUserLocation(true, true, false);
    } else {
      this.getUserLocation(false, true, false);
    }
  }

  getUserLocation(isFirst: boolean, isResetLocation: boolean, fromHelpRequest: boolean) {
    if (navigator.geolocation) {
      this.showLoading();
      this.getCurrentLocation(isFirst, isResetLocation, fromHelpRequest);
    } else {
      this.handleLocationFailed(isFirst);
    }
  }

  handleLocationFailed(isFirst: boolean) {
    this.lat = localStorage.getItem(Constants.SETTINGS_LAT);
    this.lng = localStorage.getItem(Constants.SETTINGS_LNG);
    this.loadGoogleScript();
    if (this.lat === undefined || this.lat === null) {
      if (this.key === undefined) {
        this.noLocation = true;
      }
      this.hideLoading();
      if (this.sessionType != 3) {
        this.askLocationModal.show();
      }
    } else {
      this.lng = localStorage.getItem(Constants.SETTINGS_LNG);
      this.address = localStorage.getItem("address");
      this.appService.Venue = this.address;
      if (this.sessionType == 3) {
        this.GetSongRequestByStandard(false);
        this.GetSpotifyPlaylist();
      } else {
        this.getSession(isFirst, false);
      }
    }

    this.analytics.logEvent("Web_LocationPermissionFailed");
  }

  onLocationSuccess(position: any, isFirst: boolean, fromHelpRequest: boolean) {
    this.noLocation = false;
    this.lat = position.coords.latitude;
    this.lng = position.coords.longitude;
    Helpers.saveLocation(this.lat, this.lng);
    this.loadGoogleScript();
    this.convertLatLngToAddress();
    if (isFirst) {
      this.getSession(isFirst, fromHelpRequest);
    }
    this.GetSpotifyPlaylist();

    this.analytics.logEvent("Web_LocationPermissionSuccess");
  }

  onLocationFailed(error: any, isFirst: boolean, isResetLocation: boolean) {
    this.hideLoading();
    this.loadGoogleScript();
    if (this.sessionType != 3) {
      this.askLocationModal.show();
    } else {
      this.locationGoogleModal.show();
    }
  }

  getCurrentLocation(isFirst: boolean, isResetLocation: boolean, fromHelpRequest: boolean) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        this.onLocationSuccess(position, isFirst, fromHelpRequest);
      },
      (error) => {
        this.onLocationFailed(error, isFirst, isResetLocation);
      }, {
      enableHighAccuracy: true,
      timeout: 90000,
      maximumAge: 0,
    }
    );
  }

  createPartyContinueClicked() {
    const params = new HttpParams()
      .set("alias", this.createPartyKeyword)
      .set("address", this.address)
      .set("placeId", this.currentPlaceId)
      .set("lat", this.lat)
      .set("lng", this.lng);

    this.http
      .post(webService.CreateAnAlias, params)
      .subscribe((root: CommonModel) => {
        this.createPartyModal.hide();
        if (root.code === 0) {
          this.appService.Venue = this.createPartyKeyword;
          this.addressName = this.createPartyKeyword;
          Helpers.saveLocation(this.lat, this.lng);
          localStorage.setItem(Constants.SETTINGS_ADDRESS, this.address);
          localStorage.setItem(Constants.SETTINGS_PLACEID, this.currentPlaceId);
          if (this.sessionType == 3) {
            this.GetSongRequestByStandard(false);
          }
          this.locationModalComponent.updateInfo(this.addressName, this.address);
          this.locationModalComponent.locationIcon = "./assets/img/ic_sign_plus_circle.png";

          this.standardSessionComponent.addressName = this.addressName;
          this.standardSessionComponent.subscribeFirestore();
          this.GetSpotifyPlaylist();
          $('body').removeClass('modal-open');
          this.locationModalComponent.thisModal.hide();
        }
      });
  }

  createPartyBackClicked() {
    this.getUserLocation(false, true, false);
    this.createPartyModal.hide();
    this.locationModalComponent.thisModal.show();
  }

  checkFirstTime() {
    if (this.sessionType == 3) {
      if (this.party != undefined) {
        this.getPartyById(this.party);
      } else {
        var isFirstTime = localStorage.getItem(
          Constants.SETTINGS_FIRST_TIME
        );
        if (isFirstTime == null) {
          this.introModal.show();
          localStorage.setItem(Constants.SETTINGS_FIRST_TIME, "1");
        } else {
          if (Number(isFirstTime) <= 3) {
            this.introModal.show();
          } else {
            this.getUserLocation(true, false, false);
          }
          localStorage.setItem(Constants.SETTINGS_FIRST_TIME, (Number(isFirstTime) + 1).toString());
        }
      }
      this.initStandardSession();
    }
  }

  async getPartyById(id: string) {
    var root = await this.webService.GetPartyById(id);
    if (root != null && root.code == 0) {
      this.isPremium = true;
      this.noLocation = false;
      this.lat = root.data.latitude;
      this.lng = root.data.longitude;
      this.currentPlaceId = root.data.placeId;
      this.partyName = root.data.eventName;
      this.appService.Venue = root.data.eventName;
      this.addressName = root.data.eventName;
      this.address = root.data.address;

      if (root.data.isCollectPhoneNumbers == undefined) {
        this.isCollectPhoneNumbers_party = "2";
      } else {
        this.isCollectPhoneNumbers_party = root.data.isCollectPhoneNumbers;
      }

      if (root.data.isOnlyPickSongPlaylists == undefined) {
        this.isOnlyPickSongPlaylists_party = "2";
      } else {
        this.isOnlyPickSongPlaylists_party = root.data.isOnlyPickSongPlaylists;
      }

      Helpers.saveLocation(this.lat, this.lng);
      this.loadGoogleScript();
      this.GetSongRequestByStandard(false);
      // this.convertLatLngToAddress();
      this.GetSpotifyPlaylist();
    }
    this.getPartyPlaylist(id);
  }

  async getPartyPlaylist(id: string) {
    var root = await this.webService.GetPartyPlaylist(id);
    if (root != null && root.code == 0) {
      this.isSearchSong = false;
      this.playlists = [];
      let i = 0;
      root.data.forEach((element) => {
        element.index = i;
        this.playlists.push(element);
        i++;
      });

      if (this.playlists.length > 0) {
        this.GetPartyPlaylistDetail(id, this.playlists[0].key);
      }
    }
  }

  async GetPartyPlaylistDetail(id: string, playlistKey: string) {
    var root = await this.webService.GetPartyPlaylistDetail(id, playlistKey);
    if (root != null && root.code == 0) {
      this.songs = [];
      root.data.forEach((element) => {
        this.songs.push(element);
      });
    }
  }

  btnSharePartyClicked() {
    this.menuModal.hide();
    this.qrCodeModal.show();
  }

  PartyPremiumConfirmClicked() {
    const ua = navigator.userAgent.toLowerCase();
    let url;
    if (ua.indexOf("iphone") > -1 || ua.indexOf("ipad") > -1) {
      url = `sms:+18727135442&body=Press send on this text to add your song selection to the ${this.partyName} playlist!`;
    } else {
      url = `sms:+18727135442?body=Press send on this text to add your song selection to the ${this.partyName} playlist!`;
    }
    window.location.href = url;

    localStorage.setItem(Constants.SETTINGS_PARTY_PREMIUM_REQUEST_MODAL, "2");
    localStorage.setItem(Constants.SETTINGS_PARTY_PREMIUM_REQUEST_MODAL_PARTY, this.currentPlaceId);

    this.partyPremiumRequestModalComponent.thisModal.hide();
  }

  private initForm() {
    this.phoneTypeFormGroup = this.fb.group({
      phoneType: [this.phoneType],
      phone: ["", [Validators.pattern("[A-Za-z0-9-_ ()]+")]],
    });
  }

  UpdateCheckoutSessionAMZ() {
    const amount = localStorage.getItem("CURRENT_AMOUNT");
    const params = new HttpParams()
      .set("amount", amount)
      .set("checkoutSessionId", this.amazonCheckoutSessionId);
    this.http
      .post<AMZUpdateCheckoutModel>(webService.UpdateCheckoutSessionAMZ, params)
      .subscribe((root) => {
        if (root.code === 0) {
          if (this.requestType == "2") {
            this.tipDJ();
          } else {
            this.updateRequestASong();
          }
          this.toastr.success(`Tip successfully sent to ${this.djName}!`);
          this.router.navigate([], {
            queryParams: { amazonCheckoutSessionId: null },
            queryParamsHandling: "merge",
          });
        } else {
          this.toastr.error(
            "Error",
            "Something went wrong, please check your credit/debit card information and try again."
          );
        }
      });
  }

  btnUsernameSkipClicked() {
    this.usernameModal.hide();
    this.handleCheckCountdown();
  }

  openSearchLocation() {
    if (this.sessionType != 3) {
      this.askLocationModal.show();
    }
  }

  updateClock() {
    var t = Date.parse(Date());
    var seconds = Math.floor((t / 1000) % 60);
    var minutes = Math.floor((t / 1000 / 60) % 60);

    var remainMinutes;
    var remainSeconds = 59 - seconds;

    var min = localStorage.getItem(Constants.SETTINGS_SPOTIFY_PLAYLIST_MIN);
    this.startPlaylistMinutes = Number(min);

    if (this.startPlaylistMinutes == undefined) {
      this.startPlaylistMinutes = 0;
    }

    var realCurrentStartPlaylistMinutes1 = 0;
    var realCurrentStartPlaylistMinutes2 = 0;
    var realCurrentStartPlaylistMinutes3 = 0;
    if (this.startPlaylistMinutes == 0 || this.startPlaylistMinutes == 20 || this.startPlaylistMinutes == 40) {
      realCurrentStartPlaylistMinutes1 = 0;
      realCurrentStartPlaylistMinutes2 = 20;
      realCurrentStartPlaylistMinutes3 = 40;
    } else if (this.startPlaylistMinutes < 20) {
      realCurrentStartPlaylistMinutes2 = this.startPlaylistMinutes + 20;
      realCurrentStartPlaylistMinutes3 = this.startPlaylistMinutes + 40;
      realCurrentStartPlaylistMinutes1 = this.startPlaylistMinutes;
    } else if (this.startPlaylistMinutes < 40) {
      realCurrentStartPlaylistMinutes3 = this.startPlaylistMinutes + 20;
      realCurrentStartPlaylistMinutes1 = this.startPlaylistMinutes + 40 - 60;
      realCurrentStartPlaylistMinutes2 = this.startPlaylistMinutes;
    } else {
      realCurrentStartPlaylistMinutes2 = this.startPlaylistMinutes + 40 - 60;
      realCurrentStartPlaylistMinutes3 = this.startPlaylistMinutes;
      realCurrentStartPlaylistMinutes1 = this.startPlaylistMinutes + 20 - 60;
    }

    if (minutes < realCurrentStartPlaylistMinutes1) {
      remainMinutes = realCurrentStartPlaylistMinutes1 - (minutes + 1);
    } else if (minutes < realCurrentStartPlaylistMinutes2) {
      remainMinutes = realCurrentStartPlaylistMinutes2 - (minutes + 1);
    } else if (minutes < realCurrentStartPlaylistMinutes3) {
      remainMinutes = realCurrentStartPlaylistMinutes3 - (minutes + 1);
    } else {
      remainMinutes = realCurrentStartPlaylistMinutes1 + 60 - (minutes + 1);
    }

    if (remainMinutes <= 0 && remainSeconds <= 1) {
      if (this.songs && this.songs.length > 0) {
        window.postMessage(`REFRESH_TICKER`);
      }
    }

    window.postMessage(`${remainMinutes} mins ${remainSeconds} secs`);
  }

  async getConfig() {
    var root = await this.webService.getConfig();
    if (root.code === 0) {
      this.config = root.data;
      if (this.config.hasSponsor === "1") {
        this.hasSponsor = true;
      } else {
        this.hasSponsor = false;
      }
    } else {
      this.toastr.error("Error", root.msg);
    }
  }

  getSession(isFirst: boolean, fromHelpRequest: boolean) {
    this.showLoading();
    this.events = [];
    const param =
      "?latitude=" + this.lat + "&longitude=" + this.lng + "&filterType=1";
    this.http
      .get<RootEventModel>(webService.getEvent + param)
      .subscribe((root) => {
        this.hideLoading();
        if (root.code === 0) {
          if (root.data.length === 0) {
            this.sessionType = 3;
            this.isKaraoke = true;
            this.GetSpotifyPlaylist();
            this.locationModalComponent.thisModal.show();
          } else {
            this.sessionType = 1;
            this.findLocationTitle = "Find Nearby Session";
            this.findLocationDes =
              "Tell us your current location or a location near the session you’re looking for.";
            if (!isFirst) {
              this.locationModalComponent.thisModal.show();
              if (this.sessionType != 3) {
                this.askLocationModal.show();
              }
              root.data.forEach((element) => {
                this.events.push(element);
              });
            } else {
              this.key = root.data[0].key;
              this.playlists = [];
              this.songs = [];
              this.router.navigate([""], { queryParams: { key: this.key } });
            }
          }
        } else {
          this.toastr.error("Error", root.msg);
        }
      });
  }

  showUsernameModal() {
    this.requesterName = localStorage.getItem("username");
    if (
      this.requesterName === "" ||
      this.requesterName === null ||
      this.requesterName === "null" ||
      this.requesterName === undefined
    ) {
      this.requesterName = "";
      this.usernameModal.show();
    } else {
      if (this.sessionType == 3) {
        this.requestStandardSong(
          this.requesterName,
          "",
          this.currentSongKey,
          this.currentSongTitle,
          this.currentSongArtist,
          this.currentSongThumb,
          this.duration_ms,
          this.explicit,
          "$0.00",
          "0",
          this.shoutoutMessage,
          this.requestType,
          this.lat,
          this.lng
        );
      } else {
        this.handleCheckCountdown();
      }
    }
  }

  searchSongKeywordChanged(q: string) {
    if (q === "") {
      if (this.breakSongs.length > 0) {
        this.isSearchSong = false;
      } else {
        this.isSearchSong = true;
      }

      this.searchSongs = [];
      return;
    }
    this.isSearchSong = true;
    let access_token = localStorage.getItem("SPOTIFY_TOKEN");
    if (access_token == null) {
      this.spotify.getSpotifyToken().subscribe((result) => {
        access_token = result.access_token;
        localStorage.setItem("SPOTIFY_TOKEN", result.access_token);
        this.handleSearchSong(q, access_token);
      });
    } else {
      this.handleSearchSong(q, access_token);
    }
  }

  createPartyKeywordChanged(q: string) {
    if (q === "") {
      return;
    }
  }

  handleSearchSong(q: string, access_token: string) {
    this.spotify.searchSong(q, access_token).subscribe((result) => {
      this.searchSongs = [];
      result.tracks.items.forEach((track) => {
        if (track.popularity >= 0
          && track.duration_ms >= 10000
          && track.duration_ms <= 540000) {
          let artistName = "";
          let imageUrl = "";
          if (track.album.images != null && track.album.images.length > 0) {
            imageUrl = track.album.images[0].url;
          }
          track.artists.forEach((artist) => {
            artistName += artist.name + ", ";
          });

          artistName = artistName.trim().slice(0, -1);
          track.imageUrl = imageUrl;
          track.artistName = artistName;
          this.searchSongs.push(track);
        }
      });
    });
  }

  async requestStandardSong(
    firstName: string,
    lastName: string,
    songKey: string,
    songName: string,
    songArtist: string,
    songThumb: string,
    duration_ms: string,
    explicit: string,
    packageName: string,
    packageId: string,
    requestValue: string,
    requestType: string,
    latitude: string,
    longitude: string
  ) {
    if (!latitude)
      latitude = "0";
    if (!longitude)
      longitude = "0";

    if (!this.addressName) {
      this.addressName = "";
    }

    if (!this.currentPlaceId) {
      this.currentPlaceId = "";
    }

    var uuid = this.generateUUID();

    this.spinner.show('spinner2');
    this.standardRequestModal.show();

    const root = await this.webService.requestStandardSong(firstName, lastName, songKey,
      songName, songArtist, songThumb, duration_ms,
      explicit, packageName, packageId, requestValue,
      requestType, latitude, longitude, this.addressName,
      this.address, this.currentPlaceId, "0", uuid
    );
    this.standardRequestModal.hide();
    if (root.code === 0) {
      this.currentRequestedKey = root.data;
      this.GetSongRequestByStandard(true);
      this.GetSpotifyPlaylist();
      $('body').removeClass('modal-open');
    }

    this.analytics.logEvent("Web_RequestSong");
  }

  getSessionByUser(uid: string, isFirst: boolean) {
    this.showLoading();
    this.events = [];
    const param = "?uid=" + uid;
    this.http
      .get<RootEventModel>(webService.GetEventByUser + param)
      .subscribe((root) => {
        this.hideLoading();
        if (root.code === 0) {
          if (root.data.length > 0) {
            this.findLocationTitle = "Find Nearby Session";
            this.findLocationDes =
              "Tell us your current location or a location near the session you’re looking for.";
            this.key = root.data[0].key;
            this.playlists = [];
            this.songs = [];
            this.getSessionDetail(root.data[0].key);
          } else {
            if (this.sessionType != 3) {
              this.findLocationTitle = "No Nearby Session";
              this.findLocationDes =
                "Tell us as a new location to search for sessions.";
              this.askLocationModal.show();
            }
          }
        } else {
          this.toastr.error("Error", root.msg);
        }
      });
  }

  getSessionDetail(key: string) {
    this.showLoading();
    this.playlists = [];
    const param = "?eventKey=" + key;
    this.http
      .get<EventDetailsModel>(webService.getEventDetail + param)
      .subscribe((root) => {
        this.hideLoading();
        if (root.code === 0) {
          this.djUid = root.event.uid;
          this.event = root.event;
          if (root.event.isKaraoke == "1") {
            this.sessionType = 2;
            this.isKaraoke = true;
          } else {
            this.sessionType = 1;
            this.isKaraoke = false;
          }
          if (this.event.isCollectPhoneNumbers == "1") {
            this.isCollectPhoneNumbers = true;
          } else {
            this.isCollectPhoneNumbers = false;
          }

          if (this.event.isCollectEmails == "1") {
            this.isCollectEmails = true;
          } else {
            this.isCollectEmails = false;
          }
          this.djName = root.event.djName;
          this.hasSponsor = false;
          this.config = {
            img_item_01:
              "https://firebasestorage.googleapis.com/v0/b/mejay-1542341684378.appspot.com/o/img_item_1.png?alt=media&token=4c1e8c27-c7c0-4551-97f2-e958625ac335",
            img_item_02:
              "https://firebasestorage.googleapis.com/v0/b/mejay-1542341684378.appspot.com/o/img_item_2.png?alt=media&token=359c3ea2-8a59-4db9-8eab-409ff91c8177",
            img_item_03:
              "https://firebasestorage.googleapis.com/v0/b/mejay-1542341684378.appspot.com/o/img_item_3.png?alt=media&token=5591ce8e-2d96-4772-b1ee-03515fdb8217",
            item: "",
            img_reward_01: "",
            img_reward_02: "",
            img_reward_03: "",
            hasSponsor: "",
          };
          this.profileUrl = root.event.profileUrl;
          if (
            this.profileUrl === "" ||
            this.profileUrl === "null" ||
            typeof this.profileUrl === "undefined"
          ) {
            this.profileUrl = "../../assets/img/ic_default_user.png";
          }

          if (
            this.event.venue === "" ||
            typeof this.event.venue === "undefined"
          ) {
            this.appService.Venue = " UNKNOWN";
          } else {
            this.appService.Venue = " " + root.event.venue;
          }
          let i = 0;
          root.data.forEach((element) => {
            element.index = i;
            this.playlists.push(element);
            i++;
          });

          switch (this.sessionType) {
            case 1:
            case 2:
              this.dJSessionComponent.setPlaylist(this.playlists);
              if (this.playlists.length > 0) {
                this.getPlaylistDetail(this.key, this.playlists[0].key, this.playlists[0].title);
              }
              if (this.sessionType == 2) {
                this.GetSongRequestByKaraokeEvent();
              }
              break;
            case 3:

              break;
          }
        } else {
          this.toastr.error("Error", "Something went wrong, please try again");
        }
      });
  }

  getPlaylistDetail(eventKey: string, playlistKey: string, playlistTitle: string) {
    $("#" + playlistKey).css("border", "4px solid #53B1C0");
    switch (this.sessionType) {
      case 1:
      case 2:
        this.dJSessionComponent.selectedPlaylistTitle = playlistTitle;
        break;
      case 3:

        break;
    }
    this.showLoading();
    this.songs = [];
    const param = "?eventKey=" + eventKey + "&playlistKey=" + playlistKey;
    this.http
      .get<RootSongModel>(webService.getPlaylistDetail + param)
      .subscribe((root) => {
        this.hideLoading();
        if (root.code === 0) {
          root.data.forEach((element) => {
            element.IsAd = false;
            element.Enable = true;
            this.songs.push(element);
          });
          switch (this.sessionType) {
            case 1:
            case 2:
              this.dJSessionComponent.songs = this.songs;
              if (this.sessionType == 2) {
                this.GetSongRequestByKaraokeEvent();
              }
              break;
            case 3:

              break;
          }
        } else {
          this.toastr.error("Error", root.msg);
        }
      });
  }

  handleModal() {
    this.searchSongModalComponent.thisModal.onShow.subscribe(() => {
      setTimeout(function () {
        $(".modal-backdrop")
          .not(".modal-stack")
          .css("z-index", 1040)
          .addClass("modal-stack");
      }, 0);
    });

    this.checkingRequestModal.onShow.subscribe(() => {
      setTimeout(function () {
        $(".modal-backdrop")
          .not(".modal-stack")
          .css("z-index", 1042)
          .addClass("modal-stack");
      }, 0);
    });
    this.standardRequestModal.onShow.subscribe(() => {
      setTimeout(function () {
        $(".modal-backdrop")
          .not(".modal-stack")
          .css("z-index", 1042)
          .addClass("modal-stack");
      }, 0);
    });
    this.tipModalComponent.tipModal.onShow.subscribe(() => {
      setTimeout(function () {
        $(".modal-backdrop")
          .not(".modal-stack")
          .css("z-index", 1042)
          .addClass("modal-stack");
      }, 0);
    });

    this.searchSongModalComponent.thisModal.onHide.subscribe(() => {
      this.searchKeyword = "";
      this.searchSongs = [];
    });

    this.checkingRequestModal.onHide.subscribe(() => {
      this.isRunning = false;
      this.progressValue = 3;
    });

    this.locationModalComponent.thisModal.onHide.subscribe(() => {
      this.events = [];
    });
  }

  loadExternalScript(scriptUrl: string) {
    return new Promise((resolve, reject) => {
      const scriptElement = document.createElement("script");
      scriptElement.src = scriptUrl;
      scriptElement.onload = resolve;
      document.body.appendChild(scriptElement);
    });
  }

  onShoutoutChange(value: string): void {
    this.shoutoutMessage = value;
  }

  onRequesterNameChange(value: string): void {
    this.requesterName = value;
  }

  btnUsernameDoneClicked() {
    if (this.requesterName.trim() !== "") {
      localStorage.setItem("username", this.requesterName.trim());
      this.usernameModal.hide();
      if (this.sessionType == 3) {
        this.requestStandardSong(
          this.requesterName,
          "",
          this.currentSongKey,
          this.currentSongTitle,
          this.currentSongArtist,
          this.currentSongThumb,
          this.duration_ms,
          this.explicit,
          "$0.00",
          "0",
          this.shoutoutMessage,
          this.requestType,
          this.lat,
          this.lng
        );
      } else {
        this.handleCheckCountdown();
      }
    } else {
      this.requesterName = "";
      this.toastr.error(
        "Error",
        "A name is required. You can make one up. Just remember it."
      );
    }
  }

  btnSearchSongClicked() {
    this.analytics.logEvent("Web_Btn_Search");
    this.searchSongModalComponent.updateStaticModal(false);
    this.searchSongModalComponent.thisModal.show();

    if (this.breakSongs.length > 0) {
      this.isSearchSong = false;
    }

    setTimeout(function () {
      document.getElementById("searchInput").focus()
    }, 2)
  }

  btn250Clicked() {
    this.analytics.logEvent("Web_Btn_Tip_1");
    this.amount = 500;
    this.tipModalComponent.tipModal.hide();
    this.updateApplePayPrice();
    this.CreateCheckoutSessionAMZ();
    this.paymentModal.show();
  }

  btn500Clicked() {
    this.analytics.logEvent("Web_Btn_Tip_2");
    this.amount = 1000;
    this.tipModalComponent.tipModal.hide();
    this.updateApplePayPrice();
    this.paymentModal.show();
  }

  btn1000Clicked() {
    this.analytics.logEvent("Web_Btn_Tip_3");
    this.amount = 1500;
    this.tipModalComponent.tipModal.hide();
    this.updateApplePayPrice();
    this.CreateCheckoutSessionAMZ();
    this.paymentModal.show();
  }

  btnShoutoutClicked() {
    this.analytics.logEvent("Web_Btn_Shoutout");
    this.getDisplayName();
    this.shoutoutModal.show();

    this.analytics.logEvent("Web_ShoutoutClicked");
  }

  btnTipClicked() {
    this.tipDJModalComponent.djEmail = this.event.djEmail;
    this.tipDJModalComponent.updateItemIcon();
    this.tipDJModalComponent.tipDJModal.show();

    this.analytics.logEvent("Web_Tip");
  }

  TipDJModalBtnClicked(event: string) {
    switch (event) {
      case "btnTipDJ1Clicked":
        this.requestType = '2';
        this.amount = 500;
        this.tipDJModalComponent.tipDJModal.hide();
        this.updateApplePayPrice();
        this.CreateCheckoutSessionAMZ();
        this.paymentModal.show();
        break;
      case "btnTipDJ2Clicked":
        this.requestType = '2';
        this.amount = 1000;
        this.tipDJModalComponent.tipDJModal.hide();
        this.updateApplePayPrice();
        this.CreateCheckoutSessionAMZ();
        this.paymentModal.show();
        break;
      case "btnTipDJ3Clicked":
        this.requestType = '2';
        this.amount = 1500;
        this.tipDJModalComponent.tipDJModal.hide();
        this.updateApplePayPrice();
        this.CreateCheckoutSessionAMZ();
        this.paymentModal.show();
        break;
      case "btnTipDJCustomTipClicked":
        if (this.tipDJModalComponent.customTip > 0) {
          this.requestType = '2';
          this.amount = this.tipDJModalComponent.customTip * 100;
          this.tipDJModalComponent.tipDJModal.hide();
          this.updateApplePayPrice();
          this.CreateCheckoutSessionAMZ();
          this.paymentModal.show();
        } else {
          this.toastr.show("Please enter valid amount");
        }
        break;
    }

    this.analytics.logEvent("Web_TipDJ");
  }

  getDisplayName() {
    this.requesterName = localStorage.getItem("username");
    if (
      this.requesterName === null ||
      this.requesterName === undefined ||
      this.requesterName === ""
    ) {
      this.requesterName = this.authAppService.displayName;
    }
    if (
      this.requesterName === null ||
      this.requesterName === undefined ||
      this.requesterName === ""
    ) {
      this.requesterName = "";
    }
  }

  btnRequestShoutoutClicked() {
    if (this.shoutoutMessage === "") {
      this.toastr.show("Shoutout message is empty");
      return;
    }
    if (this.shoutoutMessage.length < 10) {
      this.toastr.show("Shoutouts require a minimum of 10 characters");
      return;
    }
    localStorage.setItem("username", this.requesterName);
    this.shoutoutModal.hide();

    this.requestType = "3";
    this.requestSong(
      "",
      "",
      this.requesterName,
      "",
      "",
      this.event.key,
      this.event.eventName,
      "",
      "",
      "",
      "",
      "$0.00",
      "0",
      this.event.uid,
      this.event.djEmail,
      this.shoutoutMessage,
      this.requestType
    );

    this.analytics.logEvent("Web_RequestShoutoutClicked");
  }

  @HostListener("window:popstate", ["$event"])
  onPopState(event) {
    this.playlists = [];
    this.songs = [];
    this.events = [];
  }

  onVenueSelect(item: EventModel): void {
    this.key = item.key;
    this.locationModalComponent.thisModal.hide();
    this.playlists = [];
    this.songs = [];
    this.locations = [];
    this.router.navigate([""], { queryParams: { key: item.key } });

    this.analytics.logEvent("Web_SelectVenue");
  }

  LocationModalItemLocationClicked(item: any): void {
    this.appService.Venue = item.displayName.text;
    this.addressName = item.displayName.text;
    this.address = item.formattedAddress;
    this.currentPlaceId = item.id;
    this.lat = item.location.latitude;
    this.lng = item.location.longitude;
    Helpers.saveLocation(this.lat, this.lng);
    localStorage.setItem(Constants.SETTINGS_ADDRESS, this.address);
    localStorage.setItem(Constants.SETTINGS_PLACEID, this.currentPlaceId);
    this.songs = [];
    this.breakSongs = [];
    this.standardSessionComponent.songs = [];
    if (this.sessionType == 3) {
      this.GetSongRequestByStandard(false);
    }
    this.locationModalComponent.updateInfo(this.addressName, this.address);
    this.standardSessionComponent.addressName = this.addressName;
    this.standardSessionComponent.subscribeFirestore();
    this.GetSpotifyPlaylist();
    $('body').removeClass('modal-open');
    this.locationModalComponent.thisModal.hide();

    this.analytics.logEvent("Web_SelectLocation");
  }

  LocationModalBtnClicked() {
    if (this.addressName == null || this.addressName == "") {
      this.locationModalComponent.thisModal.hide();
      this.createPartyModal.show();
    } else {
      Helpers.saveLocation(this.lat, this.lng);
      localStorage.setItem(Constants.SETTINGS_ADDRESS, this.address);
      localStorage.setItem(Constants.SETTINGS_PLACEID, this.currentPlaceId);
      this.GetSongRequestByStandard(false);
      this.standardSessionComponent.subscribeFirestore();
      this.GetSpotifyPlaylist();
      $('body').removeClass('modal-open');
      this.locationModalComponent.thisModal.hide();

      this.analytics.logEvent("Web_SelectAutoLocation");
    }
  }

  btnPlaylistClicked() {
    this.currentPlaylistModal.show();
  }

  btnSpotifyPlaylistClicked() {
    var playlistId = localStorage.getItem(Constants.SETTINGS_SPOTIFY_PLAYLISTID);
    var uris = [];
    this.songs.forEach(s => {
      uris.push(s.Uri);
    });
    this.appService.updateSpotifyPlaylist.next(uris.toString());

    if (!playlistId) {
      this.appService.getSpotifyPlaylist.next();
    }
    window.open("spotify:playlist:" + playlistId, "_blank");
  }

  btnSYBPlaylistClicked() {
    // var playlistSYBId = localStorage.getItem(Constants.SETTINGS_SYB_PLAYLISTID);
    window.open("https://open.soundtrack.app/library", "_blank");

    this.analytics.logEvent("Web_SYBMenuButton");
  }

  btnSaveQrCodeClicked() {
    let link = document.createElement("a");
    link.download = "QrCode";
    link.href = "assets/img/ic_qrcode_2000.png";
    link.click()
    this.qrCodeModal.hide();
  }

  async btnShareQRClicked() {
    this.qrCodeModal.hide();

    const shareData = {
      title: "UJay",
      text: "Request Songs Anywhere",
      url: "https://ujay.com",
    };

    try {
      await navigator.share(shareData);
    } catch (err) {

    }
  }

  async btnCloseQRClicked() {
    this.qrCodeModal.hide();
  }

  onPlaylistSelect(item: PlaylistModel): void {
    this.playlists.forEach(function (value: PlaylistModel) {
      $("#" + value.key).css("border", "4px solid #ffffff00");
    });

    $("#" + item.key).css("border", "4px solid #53B1C0");
    this.GetPartyPlaylistDetail(this.party, item.key);
  }

  canRequestASong() {
    if (this.event.isKaraoke !== "1") {
      return true;
    }
    let latestRequest = localStorage.getItem(Constants.SETTINGS_LATEST_REQUEST);
    if (!latestRequest) {
      latestRequest = "0";
    }
    const now = Date.now();
    const limitTime = 3 * 60000;
    const timeFromLastRequest = now - Number(latestRequest);
    if (timeFromLastRequest > limitTime) {
      return true;
    }
    const timeLeft = limitTime - timeFromLastRequest;
    this.toastr.error("Make Another Request in " + Helpers.msToTime(timeLeft));
    return false;
  }

  onSearchSongSuggestSelect(item: SongModel) {
    if (item.key == "")
      return;

    this.btnUpvoteClicked(item);

    this.searchSongModalComponent.thisModal.hide();
  }

  onSpotifySongSelect(item: SpotifyTrackModel) {
    if (this.sessionType == 1 || this.sessionType == 2) {
      if (!this.canRequestASong()) {
        return;
      }
    }

    let artists = "";
    item.artists.forEach((a) => {
      artists += a.name + ",";
    });
    if (artists !== "") {
      artists = artists.substring(0, artists.length - 1);
    }
    this.currentSongThumb = item.imageUrl;
    this.currentSongTitle = item.name;
    this.currentSongArtist = artists;
    this.currentSongKey = item.uri;
    this.duration_ms = item.duration_ms.toString();
    this.explicit = item.explicit ? "1" : "0";
    this.progressValue = 3;
    this.isRunning = true;
    this.searchKeyword = "";
    this.searchSongModalComponent.thisModal.hide();

    this.handlePremiumPartyRequest();
  }

  onSongSelect(item: SongModel): void {
    this.currentSongThumb = item.Thumb;
    this.currentSongTitle = item.Title;
    this.currentSongArtist = item.Artist;
    this.currentSongKey = item.Uri;
    if (item.Duration != undefined) {
      this.duration_ms = item.Duration.toString();
    }
    this.explicit = item.Explicit ? "1" : "0";
    this.progressValue = 3;
    this.isRunning = true;
    this.searchKeyword = "";
    this.searchSongModalComponent.thisModal.hide();

    this.handlePremiumPartyRequest();
  }

  handlePremiumPartyRequest() {
    if (this.showPremiumRequestModal()) {
      this.requesterName = localStorage.getItem("username");
      if (
        this.requesterName === "" ||
        this.requesterName === null ||
        this.requesterName === "null" ||
        this.requesterName === undefined
      ) {
        this.requesterName = "";
        this.usernameModal.show();
      } else {
        this.requestPremiumPartySong(
          this.requesterName,
          "",
          this.currentSongKey,
          this.currentSongTitle,
          this.currentSongArtist,
          this.currentSongThumb,
          this.duration_ms,
          this.explicit,
          "$0.00",
          "0",
          this.shoutoutMessage,
          this.requestType,
          this.lat,
          this.lng
        );
      }
    } else {
      this.showUsernameModal();
    }
  }

  showPremiumRequestModal() {
    if (this.isCollectPhoneNumbers_party == "2")
      return false;

    var premiumRequestModal = localStorage.getItem(Constants.SETTINGS_PARTY_PREMIUM_REQUEST_MODAL);
    var premiumRequestModalParty = localStorage.getItem(Constants.SETTINGS_PARTY_PREMIUM_REQUEST_MODAL_PARTY);

    if (premiumRequestModal == undefined || premiumRequestModalParty == undefined) {
      return true;
    }

    if (premiumRequestModalParty == this.currentPlaceId && premiumRequestModal == "2") {
      return false;
    }

    return true;
  }

  async requestPremiumPartySong(
    firstName: string,
    lastName: string,
    songKey: string,
    songName: string,
    songArtist: string,
    songThumb: string,
    duration_ms: string,
    explicit: string,
    packageName: string,
    packageId: string,
    requestValue: string,
    requestType: string,
    latitude: string,
    longitude: string
  ) {
    if (!latitude)
      latitude = "0";
    if (!longitude)
      longitude = "0";

    if (!this.addressName) {
      this.addressName = "";
    }

    if (!this.currentPlaceId) {
      this.currentPlaceId = "";
    }

    var uuid = this.generateUUID();

    this.spinner.show('spinner2');

    this.partyPremiumRequestModalComponent.thisModal.show();

    const root = await this.webService.requestStandardSong(firstName, lastName, songKey,
      songName, songArtist, songThumb, duration_ms,
      explicit, packageName, packageId, requestValue,
      requestType, latitude, longitude, this.addressName,
      this.address, this.currentPlaceId, this.isPremium ? "1" : "0", uuid
    );

    if (root.code === 0) {
      this.currentRequestedKey = root.data;
      this.GetSongRequestByStandard(true);
      this.GetSpotifyPlaylist();
      $('body').removeClass('modal-open');
    }

    this.analytics.logEvent("Web_RequestSong");
  }

  onStandardSongSelect(item: SongModel): void {
    if (item.key == "")
      return;

    this.currentSongSelected = item;
    this.currentSongThumb = item.Thumb;
    this.currentSongTitle = item.Title;
    this.currentSongArtist = item.Artist;
    this.currentSongKey = item.key;
    this.currentAppleMusic = item.AppleMusic;
    this.currentRequestedBy = "Requested by " + item.Requester;

    this.upvoteModalComponent.thisModal.show();
  }

  upvoteSongItemClicked(item: SongModel): void {
    if (item.key == "")
      return;

    this.btnUpvoteClicked(item);
  }

  downvoteSongItemClicked(item: SongModel): void {
    if (item.key == "")
      return;

    this.btnDownvoteClicked(item);
  }

  handleCheckCountdown() {
    setTimeout(() => {
      this.progressValue--;
      if (this.progressValue < 0) {
        this.progressValue = 0;
        this.isRunning = false;
        this.checkingRequestModal.hide();
        this.requestType = "1";
        this.countSongRequest++;
        this.getDisplayName();
        if (this.sessionType != 3) {
          this.requestSong(
            "",
            "",
            this.requesterName,
            "",
            "",
            this.event.key,
            this.event.eventName,
            this.currentSongKey,
            this.currentSongTitle,
            this.currentSongArtist,
            this.currentSongThumb,
            "$0.00",
            "0",
            this.event.uid,
            this.event.djEmail,
            this.shoutoutMessage,
            this.requestType
          );
        } else {
          this.requestStandardSong(
            this.requesterName,
            "",
            this.currentSongKey,
            this.currentSongTitle,
            this.currentSongArtist,
            this.currentSongThumb,
            this.duration_ms,
            this.explicit,
            "$0.00",
            "0",
            this.shoutoutMessage,
            this.requestType,
            this.lat,
            this.lng
          );
        }
      }
      if (this.isRunning) {
        this.handleCheckCountdown();
      }
    }, 1000);
    this.checkingRequestModal.show();
  }

  btnCancelRequestClicked() {
    this.isRunning = false;
    this.progressValue = 3;
    this.checkingRequestModal.hide();
  }

  btnCancelPaymentClicked() {
    this.paymentModal.hide();
  }

  btnMaybeLaterClicked() {
    this.tipModalComponent.tipModal.hide();
  }

  btnCloseUpvoteClicked() {
    this.upvoteModalComponent.thisModal.hide();
  }

  btnRequestRadiusClicked() {
    this.menuModal.hide();
    this.radiusModal.show();
  }

  btnHowItWorkClicked() {
    this.introModal.hide();
    this.helpModalComponent.thisModal.show();
  }

  btnAppStoreClicked() {
    window.open("https://apps.apple.com/us/app/ujay/id1447792017", "_blank");
  }

  btnHelpClicked() {
    this.menuModal.hide();
    this.helpModalComponent.thisModal.show();
  }

  btnHelpRequestClicked() {
    this.helpModalComponent.thisModal.hide();
    this.initStandardSession();
    if (this.address) {
      // if (!this.isWhitelisted) {
      //   this.searchSongModalComponent.thisModal.show();
      // } else {
      //   this.firstUseModal.show();
      // }
      this.searchSongModalComponent.updateStaticModal(false);
      this.searchSongModalComponent.thisModal.show();
    } else {
      this.getUserLocation(true, false, true);
    }
  }

  btnIntroRequestClicked() {
    this.introModal.hide();
    this.initStandardSession();
    if (this.address) {
      if (!this.isWhitelisted) {
        this.searchSongModalComponent.updateStaticModal(false);
        this.searchSongModalComponent.thisModal.show();
      } else {
        this.firstUseModal.show();
      }
    } else {
      this.getUserLocation(true, false, true);
    }
  }

  btnContactUsClicked() {
    window.open("https://ujayapp.com/#contactus", "_blank");

    this.analytics.logEvent("Web_ContactUsClicked");
  }

  btnGetDJClicked() {
    window.open("https://apps.apple.com/us/app/ujay/id1447792017", "_blank");

    this.analytics.logEvent("Web_GetDJClicked");
  }

  btnDJResourcesClicked() {
    window.open("https://ujayapp.com", "_blank");

    this.analytics.logEvent("Web_DJResourcesClicked");
  }

  btnUpvoteModalClicked() {
    this.handleUpvote();
  }

  btnDownvoteModalClicked() {
    this.handleDownvote();
  }

  btnUpvoteClicked(item) {
    this.currentSongSelected = item;
    this.currentSongThumb = item.Thumb;
    this.currentSongTitle = item.Title;
    this.currentSongArtist = item.Artist;
    this.currentSongKey = item.key;
    this.currentAppleMusic = item.AppleMusic;
    this.currentRequestedBy = "Requested by " + item.Requester;

    this.handleUpvote();
  }

  btnDownvoteClicked(item) {
    this.currentSongSelected = item;
    this.currentSongThumb = item.Thumb;
    this.currentSongTitle = item.Title;
    this.currentSongArtist = item.Artist;
    this.currentSongKey = item.key;
    this.currentAppleMusic = item.AppleMusic;
    this.currentRequestedBy = "Requested by " + item.Requester;

    this.handleDownvote();
  }

  handleUpvote() {
    this.showLoading();

    var uuid = this.generateUUID();

    const params = new HttpParams()
      .set("songKey", this.currentSongSelected.TrackId)
      .set("uuid", uuid)
      .set("placeId", this.currentPlaceId)
      .set("lat", this.lat)
      .set("lng", this.lng);

    this.http
      .post<CommonModel>(webService.upvote, params)
      .subscribe((root) => {
        this.hideLoading();
        if (root.code === 0) {
          this.currentSongSelected.NoRequested++;
          this.currentSongSelected.Upvoted = true;
          this.currentSongSelected.Downvoted = false;
          this.standardSessionComponent.rearrange();
          this.upvoteModalComponent.thisModal.hide();
        } else if (root.code == 3) {
          this.currentSongSelected.NoRequested++;
          this.currentSongSelected.Upvoted = false;
          this.currentSongSelected.Downvoted = false;
          this.standardSessionComponent.rearrange();
          this.upvoteModalComponent.thisModal.hide();
        } else if (root.code == 1) {
          this.toastr.error("Error", "Something went wrong");
        } else {
          this.toastr.info("Info", root.msg);
        }
      });

    this.analytics.logEvent("Web_UpvoteClicked");
  }

  handleDownvote() {
    this.showLoading();

    var uuid = this.generateUUID();
    const params = new HttpParams()
      .set("songKey", this.currentSongSelected.TrackId)
      .set("uuid", uuid)
      .set("placeId", this.currentPlaceId)
      .set("lat", this.lat)
      .set("lng", this.lng);

    this.http
      .post<CommonModel>(webService.downvote, params)
      .subscribe((root) => {
        this.hideLoading();
        if (root.code === 0) {
          this.currentSongSelected.NoRequested--;
          this.currentSongSelected.Upvoted = false;
          this.currentSongSelected.Downvoted = true;
          this.standardSessionComponent.rearrange();
          this.upvoteModalComponent.thisModal.hide();
        } else if (root.code == 3) {
          this.currentSongSelected.NoRequested--;
          this.currentSongSelected.Upvoted = false;
          this.currentSongSelected.Downvoted = false;
          this.standardSessionComponent.rearrange();
          this.upvoteModalComponent.thisModal.hide();
        } else if (root.code == 1) {
          this.toastr.error("Error", "Something went wrong");
        } else {
          this.toastr.info("Info", root.msg);
        }
      });

    this.analytics.logEvent("Web_DownvoteClicked");
  }

  btnSpotifyClicked() {
    if (this.currentSongSelected.Uri == undefined) {
      this.firstUseModal.show();
      return;
    }
    if (this.isEmptyPlaylist) {
      this.firstUseModal.show();
      return;
    }
    window.open(this.currentSongSelected.Uri, "_blank");
  }

  btnAppleMusicClicked() {
    if (this.currentSongSelected == null)
      return;
    window.open(this.currentSongSelected.AppleMusic, "_blank");
  }

  StandardSessionBtnClicked(event: string) {
    switch (event) {
      case "btnSearchSongClicked":
        this.btnSearchSongClicked();
        break;
    }
  }

  onRequestSongDonated(): void {

  }

  onUpvoteDonated(): void {

  }

  googlePlaceSelected(): void {
    this.GetSongRequestTicker();
  }
}
