import { environment } from "../../environments/environment";

export class webService {

    public static getEvent = environment.root + 'getevent';
    public static getEventDetail = environment.root +  "geteventdetail";
    public static getPlaylistDetail = environment.root +  "getplaylistdetail";
    public static GetEventByUser = environment.root + 'getEventByUser';
    public static GetSongRequestByKaraokeEvent = environment.root + 'getSongRequestByKaraokeEvent';
    public static GetSongRequestByStandard = environment.root + 'getSongRequestByStandard';
    public static GetSongRequestByStandardForTicker = environment.root + 'getSongRequestByStandardForTicker';
    public static GetSpotifyPlaylist = environment.root + 'getSpotifyPlaylist';
    public static getPlaylistByPlaceId = environment.root + 'getPlaylistByPlaceId';
    public static getPlaylistByPlaceIds = environment.root + 'getPlaylistByPlaceIds';
    public static GetSpotifyPlaylistSuggest = environment.root + 'getSpotifyPlaylistSuggest';
    public static StartPlaylist = environment.root + 'startPlaylist';
    public static GetSongRequest = environment.root + 'getSongRequest';
    public static GetPerformance = environment.root + 'getPerformance';
    public static GetUser = environment.root + 'getUser';
    public static userRequest = environment.root + "userRequest";
    public static UpdateRequestASong = environment.root + "updateRequestASong";
    public static requestStandardSong = environment.root + "requestStandardSong";
    public static requestSong = environment.root + "requestSong";
    public static upvote = environment.root + "upvote";
    public static downvote = environment.root + "downvote";
    public static upvoteNextUp = environment.root + "upvoteNextUp";
    public static upvotePerformance = environment.root + "upvotePerformance";
    public static sendEmail = environment.root + "sendEmail";

    public static GetConfig = environment.root + "getConfig";
    public static GetSponsor = environment.root + "getSponsor";

    public static CreateCheckoutSessionAMZ = environment.root + "createCheckoutSessionAMZ";
    public static UpdateCheckoutSessionAMZ = environment.root + "updateCheckoutSessionAMZ";

    public static ProcessPayment = environment.root + "process-payment";
    public static ProcessPaymentSandbox = environment.root + "process-payment-sandbox";

    public static Heartbeat = environment.root + "heartbeat";

    public static GetCoinByUser = environment.root + "getCoinByUser";
    public static UpdateCoinForUser = environment.root + "updateCoinForUser";
    public static GetCoinByPhone = environment.root + "getCoinByPhone";

    public static GoogleNearbySearch = environment.root + "nearbySearchNew";

    public static CreateAnAlias = environment.root + "createAnAlias";
    public static NearbyParty = environment.root + "nearbyParty";

    public static Search = environment.root + "search";

    public static GetPartyById = environment.root + "getPartyById";
    public static GetPartyPlaylist = environment.root + "getPartyPlaylist";
    public static GetPartyPlaylistDetail = environment.root + "getPartyPlaylistDetail";

    public static GetPlaylistSuggestion = environment.root + "getPlaylistSuggestion";

    public static GoogleGeocode(lat: any, lng: any) {
        return `https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyDgJmdJ8zIRjCJL8eWGsy0FIItTgulrLK0&latlng=${lat},${lng}`
    }

    public static TomTomGeocode(lat: any, lng: any) {
        return `https://api.tomtom.com/search/2/reverseGeocode/${lat},${lng}.json?key=XySTYxZZaAkDmTmoAZdDjo74fog2BJ1z&radius=100`
    }

    public static PlusCode(lat: any, lng: any) {
        return `https://plus.codes/api?address=${lat},${lng}`
    }
}