import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { SongModel } from "../../../models/SongModel";
import { shareReplay } from "rxjs/operators";
import { Enumerable } from 'sharp-collections';
import { AngularFirestore } from '@angular/fire/firestore';
import { Helpers } from "../../../helpers/Helpers";

@Component({
  selector: "UpNext",
  templateUrl: "./upnext.component.html",
})
export class UpNextComponent implements OnInit, OnDestroy {

  @Output() btnClicked = new EventEmitter<string>();
  @Output() standardSongItemClicked = new EventEmitter<SongModel>();

  songs = [];
  noLocation = false;

  constructor(private firestore: AngularFirestore) {
    var firebaseSubscribe = this.firestore.collection('tb_first_friday_request')
      .valueChanges()
      .pipe(shareReplay());

    firebaseSubscribe.subscribe(data => {
      if (!data)
        return;
      var changedData = data as any;
      const enumerable = Enumerable.from(this.songs);
      var uuid = Helpers.generateUUID();
      changedData.forEach((item) => {
        var foundSong = enumerable.where(x => x.key == item.key).firstOrDefault();
        if (!foundSong) {
          var upvoted = false;
          if (item.uuids.includes(uuid)) {
            upvoted = true;
          }
          const s = {
            key: item.key, Uri: item.songKey,
            TrackId: item.songKey, Artist: item.songArtist,
            Thumb: item.songThumb, Title: item.songName,
            Enable: true, NoRequested: item.noRequested,
            Requester: item.firstName + " " + item.lastName,
            AppleMusic: item.link_apple_music,
            UUID: item.uuid, Upvoted: upvoted, Time: item.time,
            RequesterText: "Requested by " + item.firstName + " " + item.lastName
          } as SongModel;
          this.songs.push(s);
        }
      })
    });
  }

  ngOnDestroy(): void {
    this.songs.forEach(s => {
      // s.FirebaseSubscribe.unsubscribe();
    });
  }

  ngOnInit(): void {

  }

  rearrange(): void {
    var tempSongs = this.songs;
    this.songs = [];
    tempSongs.sort((a, b) => a.Time - b.Time);
    tempSongs.sort((a, b) => b.NoRequested - a.NoRequested);
    tempSongs.forEach(s => {
      this.songs.push(s);
    });
  }

  updateSongs(songs: string[]): void {
    this.songs = songs;
    this.songs.forEach(s => {
      var firebaseSubscribe = this.firestore.collection('tb_first_friday_request')
        .doc(s.key)
        .snapshotChanges()
        .pipe(shareReplay());
      s.FirebaseSubscribe = firebaseSubscribe;

      const enumerable = Enumerable.from(this.songs);
      firebaseSubscribe.subscribe(data => {
        var changedData = data as any;
        if (changedData.type == "removed") {
          var foundSong = enumerable.where(x => x.key == changedData.payload.id).firstOrDefault();
          if (foundSong) {
            var index = this.songs.indexOf(foundSong);
            this.songs.splice(index, 1);
          }
        } else {
          var foundSong = enumerable.where(x => x.key == changedData.payload.id).firstOrDefault();
          if (foundSong) {
            foundSong.NoRequested = changedData.payload.data().noRequested;
            this.rearrange();
          }
        }
      });
    });
  }

  onSongSelect(item: SongModel): void {
    if (item.key == "")
      return;
    this.standardSongItemClicked.emit(item);
  }

  btnSearchSongClicked() {
    this.btnClicked.emit("btnSearchSongClicked");
  }
}
