import { HttpClient, HttpParams } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AMZUpdateCheckoutModel } from "../../models/AMZUpdateCheckout";
import { webService } from "../../webServices/api";
import { Location } from '@angular/common';

@Component({
  templateUrl: "azp.component.html",
})
export class AZPComponent implements OnInit {

  amazonCheckoutSessionId: string;

  constructor(
    private location: Location,
    private http: HttpClient,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((params) => {
      this.amazonCheckoutSessionId = params["amazonCheckoutSessionId"];
      if (this.amazonCheckoutSessionId !== undefined) {
        localStorage.setItem("amazonCheckoutSessionId", this.amazonCheckoutSessionId);
        this.UpdateCheckoutSessionAMZ();
      }
    });
  }

  ngOnInit(): void {

  }

  UpdateCheckoutSessionAMZ() {
    var amount = localStorage.getItem("CURRENT_AMOUNT");
    const params = new HttpParams()
      .set("currentPageUrl", "http://localhost:4200/azp")
      .set("amount", amount)
      .set("checkoutSessionId", this.amazonCheckoutSessionId);
    this.http.post<AMZUpdateCheckoutModel>(webService.UpdateCheckoutSessionAMZ, params)
      .subscribe((root) => {
        if (root.code == 0) {
          this.location.back();
        } else {
          this.toastr.error("Error", root.msg);
        }
      });
  }
}