import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";
import { PlaylistModel } from "../../../models/PlaylistModel";
import { SongModel } from "../../../models/SongModel";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

@Component({
    selector: "SearchSongModal",
    templateUrl: "./searchSongModal.component.html",
})
export class SearchSongModalComponent implements OnInit, AfterViewInit {
    @ViewChild("searchSongModal") public thisModal: ModalDirective;
    @Output() onSpotifySongSelectOutput = new EventEmitter<any>();
    @Output() onPlaylistSelectOutput = new EventEmitter<PlaylistModel>();
    @Output() onSongSelectOutput = new EventEmitter<SongModel>();
    @Output() searchSongKeywordChangedOutput = new EventEmitter<string>();

    @Input() searchSongs: [];
    @Input() breakSongs: [];
    @Input() playlists: [];
    @Input() songs: [];
    @Input() isSearchSong: boolean;
    @Input() searchKeyword: string;
    @Input() isPremium: boolean;

    isStaticModal: boolean = false;
    isFirstTimeLostFocus = true;

    userQuestionUpdate = new Subject<string>();

    constructor() {
        this.userQuestionUpdate.pipe(
            debounceTime(500),
            distinctUntilChanged())
            .subscribe(q => {
                this.searchSongKeywordChangedOutput.emit(q);
            });
    }

    ngAfterViewInit(): void {
        this.thisModal.onHide.subscribe(() => {
            this.searchKeyword = "";
            this.searchSongs = [];
        });
    }

    ngOnInit(): void {

    }

    focusOutFunction() {
        setTimeout(function () {
            if (this.isFirstTimeLostFocus) {
                this.isFirstTimeLostFocus = false;
                document.getElementById("searchInput").focus();
            }
        }, 10)
    }

    onSongSelect(item: SongModel): void {
        this.onSongSelectOutput.emit(item);
    }

    onPlaylistSelect(item: PlaylistModel): void {
        this.onPlaylistSelectOutput.emit(item);
    }

    updateStaticModal(isStatic: boolean) {
        this.isStaticModal = isStatic;
        if (isStatic) {
            this.thisModal.config = { backdrop: 'static' }
        } else {
            this.thisModal.config = { backdrop: false }
        }

        setTimeout(function () {
            document.getElementById("searchInput").focus()
        }, 500)
    }

    onSpotifySongSelect(item: any): void {
        this.onSpotifySongSelectOutput.emit(item);
    }

    btnBackClicked() {
        this.thisModal.hide();
    }
}
