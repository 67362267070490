import { HttpClient, HttpParams } from "@angular/common/http";
import {
    ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../../auth/AuthService";
import { AppService } from "../../services/app.service";
import { Location } from "@angular/common";
import { Constants } from "../../helpers/Constants";
import { RequestedModel } from "../../models/RequestedModel";
import { webService } from "../../webServices/api";
import { PremiumComponent } from "./premium.component";
import { CommonModel } from "../../models/CommonModel";
import { WebService } from "../../webServices/WebService";

declare var $: any;
declare var window: any;

export abstract class LogicComponent extends PremiumComponent {

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        public http: HttpClient,
        public webService: WebService,
        public toastr: ToastrService,
        public spinner: NgxSpinnerService,
        public authAppService: AuthService,
        public appService: AppService,
        public location: Location
    ) {
        super(
            router,
            route,
            http,
            webService,
            toastr,
            spinner,
            authAppService,
            appService,
            location
        );
    }

    updateRequestASong() {
        this.currentRequestedKey = localStorage.getItem(
            Constants.SETTINGS_CURRENT_REQUESTED_KEY
        );
        this.requestType = localStorage.getItem(Constants.SETTINGS_REQUEST_TYPE);
        if (this.currentRequestedKey === "" || this.amount === 0) {
            return;
        }

        let packageId = "";
        let packageName = "";
        const rightAmount = this.amount / 100;
        packageId = rightAmount + "";
        const formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
        });
        packageName = formatter.format(rightAmount);
        const params = new HttpParams()
            .set("key", this.currentRequestedKey)
            .set("packageId", packageId)
            .set("packageName", packageName);

        this.spinner.show();

        this.http
            .post(webService.UpdateRequestASong, params)
            .subscribe((root: CommonModel) => {
                this.spinner.hide();
                if (root.code === 0) {
                    localStorage.setItem("CURRENT_REQUESTED_KEY", "");
                    localStorage.setItem("CURRENT_AMOUNT", "");
                    this.toastr.success(`Tip successfully sent to ${this.djName}!`);
                    this.paymentModal.hide();
                } else {
                    this.toastr.error("Error", "Something went wrong");
                }
            });
    }

    onDonated(): void {

    }

    requestSong(
        uid: string,
        email: string,
        firstName: string,
        lastName: string,
        avatar: string,
        eventKey: string,
        eventName: string,
        songKey: string,
        songName: string,
        songArtist: string,
        songThumb: string,
        packageName: string,
        packageId: string,
        djUid: string,
        djEmail: string,
        requestValue: string,
        requestType: string
    ) {
        const params = new HttpParams()
            .set("uid", uid)
            .set("email", email)
            .set("firstName", firstName)
            .set("lastName", lastName)
            .set("avatar", avatar)
            .set("eventKey", eventKey)
            .set("eventName", eventName)
            .set("songKey", songKey)
            .set("songName", songName)
            .set("songArtist", songArtist)
            .set("songThumb", songThumb)
            .set("packageName", packageName)
            .set("packageId", packageId)
            .set("djUid", djUid)
            .set("djEmail", djEmail)
            .set("requestValue", requestValue)
            .set("requestType", requestType);

        this.spinner.show();

        this.http
            .post(webService.userRequest, params)
            .subscribe((root: RequestedModel) => {
                this.spinner.hide();
                if (root.code === 0) {
                    this.currentRequestedKey = root.data;
                    localStorage.setItem(
                        Constants.SETTINGS_CURRENT_REQUESTED_KEY,
                        this.currentRequestedKey
                    );
                    localStorage.setItem(Constants.SETTINGS_REQUEST_TYPE, requestType);
                    localStorage.setItem(
                        Constants.SETTINGS_LATEST_REQUEST,
                        Date.now().toString()
                    );

                    this.checkPremiumSession();

                    if (this.event.isTipping === "1" && requestType !== "2") {
                        this.tipModalComponent.profileUrl = this.event.profileUrl;
                        this.tipModalComponent.djName = this.event.djName;
                        this.tipModalComponent.djEmail = this.event.djEmail;
                        this.tipModalComponent.updateItemIcon();
                        this.tipModalComponent.subTitle = " got your FREE request.";
                        this.tipModalComponent.tipModal.show();
                    }

                    if (requestType === "1") {
                        this.toastr.success(
                            "Information",
                            this.event.djName + " has received your request."
                        );
                    } else if (requestType === "2") {
                        this.toastr.success(
                            "Information",
                            this.event.djName + " has received your tipping."
                        );
                    } else if (requestType === "3") {
                        this.toastr.success(
                            "Information",
                            this.event.djName + " has received your message."
                        );
                    }
                } else {
                    this.toastr.error("Error", "Something went wrong");
                }
            });
    }
}