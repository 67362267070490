import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule, ToastContainerModule } from "ngx-toastr";
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { environment } from "../environments/environment";

import { AppComponent } from "./app.component";

// Import containers
import { DefaultLayoutComponent } from "./containers";

import { P404Component } from "./views/error/404.component";
import { P500Component } from "./views/error/500.component";
import { LoginComponent } from "./views/login/login.component";
import { RegisterComponent } from "./views/register/register.component";
import { DetailComponent } from "./views/detail/detail.component";
import { MainComponent } from "./views/main/main.component";
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
export function playerFactory() {
  return player;
}
import { NgMarqueeModule } from 'ng-marquee';

const APP_CONTAINERS = [DefaultLayoutComponent];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from "@coreui/angular";

// Import routing module
import { AppRoutingModule } from "./app.routing";

// Import 3rd party components
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TabsModule } from "ngx-bootstrap/tabs";
import { ChartsModule } from "ng2-charts";
import { MessageService } from "./events/MessageService";
import { SpotifyService } from "./services/SpotifyService";

import { SocialLoginModule } from 'angularx-social-login';

import { ShareButtonsPopupModule } from "ngx-sharebuttons/popup";
import { ShareIconsModule } from "ngx-sharebuttons/icons";
import { ShareModule } from "ngx-sharebuttons";
import { ShareButtonsModule } from "ngx-sharebuttons/buttons";

import { ModalModule } from "ngx-bootstrap/modal";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AZPComponent } from "./views/azpResult/azp.component";
import { NgxFlagPickerModule } from "ngx-flag-picker";
import { GenreComponent } from "./views/genre/genre.component";
import { BPMComponent } from "./views/bpm/bpm.component";
import { PartyDashboardComponent } from "./views/partyDashboard/partyDashboard.component";
import { MoodComponent } from "./views/mood/mood.component";
import { TipModalComponent } from './views/modal/tip-modal/tip-modal.component';
import { TipDJModalComponent } from "./views/modal/tipDJModal/TipDJModal.component";
import { AfterRequestModalComponent } from "./views/modal/afterRequestModal/AfterRequestModal.component";
import { StandardSessionComponent } from "./views/detail/standard/standard.component";
import { DJSessionComponent } from "./views/detail/djSession/djsession.component";
import { FristFridayComponent } from "./views/firstFriday/fristfriday.component";
import { UpNextComponent } from "./views/firstFriday/ui/upnext.component";
import { DonateModalComponent } from "./views/modal/donate-modal/donate-modal.component";
import { PerformanceComponent } from "./views/firstFriday/ui/performance.component";
import { PartyComponent } from "./views/detail/party/party.component";
import { EmailModalComponent } from "./views/modal/emailModal/emailModal.component";
import { LocationModalComponent } from "./views/modal/locationModal/locationModal.component";
import { SearchSongModalComponent } from "./views/modal/searchSongModal/searchSongModal.component";
import { HelpModalComponent } from "./views/modal/helpModal/helpModal.component";
import { WebService } from "./webServices/WebService";
import { PartyPremiumRequestModalComponent } from "./views/modal/partyPremiumRequestModal/partyPremiumRequestModal.component";
import { UpvoteModalComponent } from "./views/modal/upvoteModal/upvoteModal.component";

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    HttpClientModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    NgxSpinnerModule,
    LazyLoadImageModule,
    ToastrModule.forRoot(),
    ToastContainerModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    LottieModule.forRoot({ player: playerFactory }),
    BsDropdownModule,
    NgxSpinnerModule,
    ShareButtonsPopupModule,
    ShareButtonsModule,
    ShareIconsModule,
    NgMarqueeModule,
    ShareModule,
    SocialLoginModule,
    NgxFlagPickerModule,
    ScrollingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    ModalModule.forRoot(),
    ProgressbarModule.forRoot(),
    CarouselModule,
    NgCircleProgressModule.forRoot({
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300,
    })
  ],
  declarations: [
    AppComponent,
    APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    RegisterComponent,
    DetailComponent,
    FristFridayComponent,
    MainComponent,
    AZPComponent,
    GenreComponent,
    BPMComponent,
    PartyDashboardComponent,
    MoodComponent,
    TipModalComponent,
    TipDJModalComponent,
    EmailModalComponent,
    LocationModalComponent,
    SearchSongModalComponent,
    HelpModalComponent,
    AfterRequestModalComponent,
    UpvoteModalComponent,
    StandardSessionComponent,
    PartyPremiumRequestModalComponent,
    DJSessionComponent,
    UpNextComponent,
    PerformanceComponent,
    DonateModalComponent,
    PartyComponent
  ],
  providers: [
    [MessageService],
    [SpotifyService, WebService]
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
