import { HttpClient, HttpParams } from "@angular/common/http";
import {
  Component,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalDirective } from "ngx-bootstrap/modal";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../../auth/AuthService";
import { AppService } from "../../services/app.service";
import { Location } from "@angular/common";
import { Constants } from "../../helpers/Constants";
import { PaymentComponent } from "./payment.component";
import { webService } from "../../webServices/api";
import { EmailModalComponent } from "../modal/emailModal/emailModal.component";
import { WebService } from "../../webServices/WebService";

declare var $: any;
declare var window: any;

@Component({
  template: ''
})
export abstract class PremiumComponent extends PaymentComponent {

  @ViewChild("premiumModal") public premiumModal: ModalDirective;

  @ViewChild(EmailModalComponent) emailModalComponent: EmailModalComponent;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public http: HttpClient,
    public webService: WebService,
    public toastr: ToastrService,
    public spinner: NgxSpinnerService,
    public authAppService: AuthService,
    public appService: AppService,
    public location: Location
  ) {
    super(
      router,
      route,
      http,
      webService,
      toastr,
      spinner,
      authAppService,
      appService,
      location
    );
  }

  EmailModalBtnClicked() {
    if (this.emailModalComponent.email === "") {
      this.toastr.show("Your email is empty");
      return;
    }

    this.premiumModal.hide();
    
    const params = new HttpParams()
      .set("email", this.emailModalComponent.email)
      .set("eventKey", this.event.key)
      .set("eventName", this.event.eventName);

    this.spinner.show();

    this.http
      .post(webService.sendEmail, params)
      .subscribe((root: any) => {
        this.spinner.hide();
        if (root.code === 0) {
          this.toastr.info("Info", "We got your email. Thank You!.");
          this.emailModalComponent.thisModal.hide();
        } else {
          this.toastr.error("Error", "Something went wrong");
        }
      });

    this.spinner.hide();
  }

  checkPremiumSession() {
    if (this.event.isCollectEmails === "1" || this.event.isCollectPhoneNumbers === "1") {
      this.premiumModal.show();
    }
  }

  btnPhoneNumberClicked() {
    const ua = navigator.userAgent.toLowerCase();
    let url;
    if (ua.indexOf("iphone") > -1 || ua.indexOf("ipad") > -1) {
      url = "sms:+18727135442&body=Press send to confirm your request and keep up with " + this.djName;
    } else {
      url = "sms:+18727135442?body=Press send to confirm your request and keep up with " + this.djName;
    }
    window.location.href = url;

    localStorage.setItem(Constants.SETTINGS_PREMIUM_PHONE, this.event.key);
  }

  btnEmailClicked() {
    this.premiumModal.hide();
    this.emailModalComponent.thisModal.show();
  }
}