import { HttpClient, HttpParams } from "@angular/common/http";
import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../../auth/AuthService";
import { Constants } from "../../helpers/Constants";
import { EventDetailsModel } from "../../models/EventDetailModel";
import { EventModel, RootEventModel } from "../../models/EventModel";
import { RequestedModel } from "../../models/RequestedModel";
import { AppService } from "../../services/app.service";
import { webService } from "../../webServices/api";
import { Location } from "@angular/common";
import { AMZUpdateCheckoutModel } from "../../models/AMZUpdateCheckout";
import { ModalDirective } from "ngx-bootstrap/modal";
import { TipDJModalComponent } from "../modal/tipDJModal/TipDJModal.component";
import { PremiumComponent } from "../base/premium.component";
import { WebService } from "../../webServices/WebService";

declare var $: any;
declare var window: any;

@Component({
  templateUrl: "main.component.html",
})
export class MainComponent
  extends PremiumComponent
  implements OnInit, AfterViewInit {

  @ViewChild("checkingShoutoutRequestModal")
  public checkingShoutoutRequestModal: ModalDirective;
  @ViewChild(TipDJModalComponent) tipDJModalComponent: TipDJModalComponent;

  uuid: string;
  number: string;
  amazonCheckoutSessionId: string;
  hasNoData = false;
  event: EventModel;
  events = [];

  requesterName = "";
  shoutoutMessage = "";
  shoutoutPlaceholder =
    "Type your message. \nEx. I’d like to give a shoutout to ";
  requestType = "1";
  currentRequestedKey = "";

  djName: string;
  profileUrl: string;

  defaultImage = "../../assets/images/image01.png";

  config = {
    img_item_01: "",
    img_item_02: "",
    img_item_03: "",
    item: "",
    img_reward_01: "",
    img_reward_02: "",
    img_reward_03: "",
    hasSponsor: "",
  };

  isRunning = false;
  progressValue = 3;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public authAppService: AuthService,
    public appService: AppService,
    public http: HttpClient,
    public webService: WebService,
    public toastr: ToastrService,
    public spinner: NgxSpinnerService,
    public location: Location
  ) {
    super(
      router,
      route,
      http,
      webService,
      toastr,
      spinner,
      authAppService,
      appService,
      location
    );
  }

  refreshPage(): void {
        
  }

  ngOnInit(): void {
    this.appService.Venue = " LOADING...";
    this.route.queryParams.subscribe((params) => {
      this.key = params["key"];
      this.uuid = params["dj"];
      if (this.key !== undefined) {
        this.getSessionDetail(this.key);
      } else if (this.uuid !== undefined) {
        this.getSessionByUser(this.uuid, true);
      } else {
        this.appService.Venue = " UNKNOWN";
        // this.getUserLocation(true, false);
      }

      this.number = params["n"];
      if (this.number !== undefined) {
        localStorage.setItem(Constants.SETTINGS_NUMBER, this.number);
      } else {
        this.number = localStorage.getItem(Constants.SETTINGS_NUMBER);
      }
      this.amazonCheckoutSessionId = params["amazonCheckoutSessionId"];
      if (this.amazonCheckoutSessionId !== undefined) {
        localStorage.setItem(
          "amazonCheckoutSessionId",
          this.amazonCheckoutSessionId
        );
        this.UpdateCheckoutSessionAMZ();
      }
    });
  }

  onDestroy(): void {
    
  }

  TipDJModalBtnClicked(event: string) {
    switch (event) {
      case "btnTipDJ1Clicked":
        this.amount = 500;
        this.tipDJModalComponent.tipDJModal.hide();
        this.updateApplePayPrice();
        this.CreateCheckoutSessionAMZ();
        this.paymentModal.show();
        break;
      case "btnTipDJ2Clicked":
        this.amount = 1000;
        this.tipDJModalComponent.tipDJModal.hide();
        this.updateApplePayPrice();
        this.CreateCheckoutSessionAMZ();
        this.paymentModal.show();
        break;
      case "btnTipDJ3Clicked":
        this.amount = 1500;
        this.tipDJModalComponent.tipDJModal.hide();
        this.updateApplePayPrice();
        this.CreateCheckoutSessionAMZ();
        this.paymentModal.show();
        break;
      case "btnTipDJCustomTipClicked":
        if (this.tipDJModalComponent.customTip > 0) {
          this.amount = this.tipDJModalComponent.customTip * 100;
          this.tipDJModalComponent.tipDJModal.hide();
          this.updateApplePayPrice();
          this.CreateCheckoutSessionAMZ();
          this.paymentModal.show();
        } else {
          this.toastr.show("Please enter valid amount");
        }
        break;
    }
  }

  btnCancelRequestClicked() {
    this.checkingShoutoutRequestModal.hide();
  }

  handleCheckCountdown() {
    setTimeout(() => {
      this.progressValue--;
      if (this.progressValue < 0) {
        this.progressValue = 0;
        this.isRunning = false;
        this.checkingShoutoutRequestModal.hide();
        this.requestType = "3";
        this.requestShoutout(
          "",
          "",
          this.requesterName,
          "",
          "",
          this.event.key,
          this.event.eventName,
          "",
          "",
          "",
          "",
          "$0.00",
          "0",
          this.event.uid,
          this.event.djEmail,
          this.shoutoutMessage,
          this.requestType
        );
      }
      if (this.isRunning) {
        this.handleCheckCountdown();
      }
    }, 1000);
    this.checkingShoutoutRequestModal.show();
  }

  UpdateCheckoutSessionAMZ() {
    const amount = localStorage.getItem("CURRENT_AMOUNT");
    const params = new HttpParams()
      .set("amount", amount)
      .set("checkoutSessionId", this.amazonCheckoutSessionId);
    this.http
      .post<AMZUpdateCheckoutModel>(webService.UpdateCheckoutSessionAMZ, params)
      .subscribe((root) => {
        if (root.code === 0) {
          this.toastr.success(`Tip successfully sent to ${this.djName}!`);
          this.router.navigate([], {
            queryParams: { amazonCheckoutSessionId: null },
            queryParamsHandling: "merge",
          });
        } else {
          this.toastr.error(
            "Error",
            "Something went wrong, please check your credit/debit card information and try again."
          );
        }
      });
  }

  ngAfterViewInit(): void {
    this.handlePayment();
  }


  btnFollowClicked() {
    const ua = navigator.userAgent.toLowerCase();
    let url;
    if (ua.indexOf("iphone") > -1 || ua.indexOf("ipad") > -1) {
      url = `sms:+18727135442&body=Press send on this message keep up with ${this.djName} shows`;
    } else {
      url = `sms:+18727135442?body=Press send on this message keep up with ${this.djName} shows`;
    }
    window.location.href = url;
  }

  btnTipClicked() {
    this.tipDJModalComponent.djEmail = this.event.djEmail;
    this.tipDJModalComponent.updateItemIcon();
    this.tipDJModalComponent.tipDJModal.show();
  }

  btnShoutoutClicked() {
    this.getDisplayName();
    this.shoutoutModal.show();
  }

  getDisplayName() {
    this.requesterName = localStorage.getItem("username");
    if (
      this.requesterName === null ||
      this.requesterName === undefined ||
      this.requesterName === ""
    ) {
      this.requesterName = this.authAppService.displayName;
    }
    if (
      this.requesterName === null ||
      this.requesterName === undefined ||
      this.requesterName === ""
    ) {
      this.requesterName = "";
    }
  }

  onRequesterNameChange(value: string): void {
    this.requesterName = value;
  }

  onShoutoutChange(value: string): void {
    this.shoutoutMessage = value;
  }

  btnRequestShoutoutClicked() {
    if (this.shoutoutMessage === "") {
      this.toastr.show("Shoutout message is empty");
      return;
    }
    if (this.shoutoutMessage.length < 10) {
      this.toastr.show("Shoutouts require a minimum of 10 characters");
      return;
    }
    localStorage.setItem("username", this.requesterName);
    this.shoutoutModal.hide();
    this.progressValue = 3;
    this.isRunning = true;
    this.handleCheckCountdown();
  }

  requestShoutout(
    uid: string,
    email: string,
    firstName: string,
    lastName: string,
    avatar: string,
    eventKey: string,
    eventName: string,
    songKey: string,
    songName: string,
    songArtist: string,
    songThumb: string,
    packageName: string,
    packageId: string,
    djUid: string,
    djEmail: string,
    requestValue: string,
    requestType: string
  ) {
    const params = new HttpParams()
      .set("uid", uid)
      .set("email", email)
      .set("firstName", firstName)
      .set("lastName", lastName)
      .set("avatar", avatar)
      .set("eventKey", eventKey)
      .set("eventName", eventName)
      .set("songKey", songKey)
      .set("songName", songName)
      .set("songArtist", songArtist)
      .set("songThumb", songThumb)
      .set("packageName", packageName)
      .set("packageId", packageId)
      .set("djUid", djUid)
      .set("djEmail", djEmail)
      .set("requestValue", requestValue)
      .set("requestType", requestType);

    this.spinner.show();

    this.http
      .post(webService.userRequest, params)
      .subscribe((root: RequestedModel) => {
        this.spinner.hide();
        if (root.code === 0) {
          this.currentRequestedKey = root.data;
          localStorage.setItem(
            Constants.SETTINGS_CURRENT_REQUESTED_KEY,
            this.currentRequestedKey
          );
          localStorage.setItem(Constants.SETTINGS_REQUEST_TYPE, requestType);
          localStorage.setItem(
            Constants.SETTINGS_LATEST_REQUEST,
            Date.now().toString()
          );
          if (this.event.isTipping === "1") {
            this.tipModalComponent.profileUrl = this.event.profileUrl;
            this.tipModalComponent.djName = this.event.djName;
            this.tipModalComponent.djEmail = this.event.djEmail;
            this.tipModalComponent.updateItemIcon();
            this.tipModalComponent.subTitle = " got your shoutout request."
            this.tipModalComponent.tipModal.show();
          }
          if (requestType === "1") {
            this.toastr.success(
              "Information",
              this.event.djName + " has received your request."
            );
          } else if (requestType === "3") {
            this.toastr.success(
              "Information",
              this.event.djName + " has received your message."
            );
          }
          this.GetSongRequestByKaraokeEvent();
        } else {
          this.toastr.error("Error", "Something went wrong");
        }
      });
  }

  getSessionByUser(uid: string, isFirst: boolean) {
    this.spinner.show();
    this.events = [];
    const param = "?uid=" + uid;
    this.http
      .get<RootEventModel>(webService.GetEventByUser + param)
      .subscribe((root) => {
        this.spinner.hide();
        if (root.code === 0) {
          if (root.data.length > 0) {
            this.findLocationTitle = "Find Nearby DJ Session";
            this.findLocationDes =
              "Tell us your current location or a location near the session you’re looking for.";
            this.key = root.data[0].key;
            this.getSessionDetail(root.data[0].key);
          } else {
            this.findLocationTitle = "No Nearby DJ Session";
            this.findLocationDes =
              "Tell us as a new location to search for sessions.";
            this.askLocationModal.show();
          }
        } else {
          this.toastr.error("Error", root.msg);
        }
      });
  }

  getSessionDetail(key: string) {
    this.spinner.show();
    const param = "?eventKey=" + key;
    this.http
      .get<EventDetailsModel>(webService.getEventDetail + param)
      .subscribe((root) => {
        this.spinner.hide();
        if (root.code === 0) {
          this.event = root.event;
          localStorage.setItem(Constants.EVENT_KEY, this.event.key);
          localStorage.setItem(Constants.EVENT_NAME, this.event.eventName);
          localStorage.setItem(Constants.EVENT_UID, this.event.uid);
          localStorage.setItem(Constants.EVENT_DJEMAIL, this.event.djEmail);
          localStorage.setItem(Constants.EVENT_VENUE, this.event.venue);
          localStorage.setItem(
            Constants.EVENT_IS_KARAOKE,
            this.event.isKaraoke
          );
          localStorage.setItem(
            Constants.EVENT_IS_TIPPING,
            this.event.isTipping
          );

          if (root.event.isKaraoke == "1") {
            this.isKaraoke = true;
          } else {
            this.isKaraoke = false;
          }
          if (root.event.isTipping == "1") {
            this.isTipping = true;
          } else {
            this.isTipping = false;
          }
          this.djName = root.event.djName;
          this.profileUrl = root.event.profileUrl;
          if (
            this.profileUrl === "" ||
            this.profileUrl === "null" ||
            typeof this.profileUrl === "undefined"
          ) {
            this.profileUrl = "../../assets/img/ic_default_user.png";
          }
          localStorage.setItem(Constants.EVENT_DJNAME, this.djName);
          localStorage.setItem(Constants.EVENT_PROFILE_URL, this.profileUrl);

          if (
            this.event.venue === "" ||
            typeof this.event.venue === "undefined"
          ) {
            this.appService.Venue = " UNKNOWN";
          } else {
            this.appService.Venue = " " + root.event.venue;
          }
          this.GetSongRequestByKaraokeEvent();
        } else {
          this.toastr.error("Error", "Something went wrong, please try again");
        }
      });
  }

  onRequestSongDonated(): void {
        
  }
  onUpvoteDonated(): void {
      
  }
  updateRequestASong(): void {
      
  }
  tipDJ(): void {
      
  }

  googlePlaceSelected(): void {
        
  }
}
