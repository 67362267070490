import { HttpClient } from "@angular/common/http";
import { AfterViewInit, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../../auth/AuthService";
import { AppService } from "../../services/app.service";
import { BaseComponent } from "../base/base.component";
import { Location } from "@angular/common";
import { SongRequestModel } from "../../models/SongRequestModel";
import { Helpers } from "../../helpers/Helpers";
import { WebService } from "../../webServices/WebService";

declare var window: any;
declare var $: any;

@Component({
  templateUrl: "partyDashboard.component.html",
})
export class PartyDashboardComponent
  extends BaseComponent
  implements OnInit, AfterViewInit {

  songs = [];
  genres = [];
  bpms = [];
  moods = [];
  hasTopPart = false;
  hasGenre = false;
  hasBPM = false;
  hasMood = false;
  genrePercent = 0;
  genreTitle = "";

  bpmPercent = 0;
  bpmTitle = "";

  moodPercent = 0;
  moodTitle = "";

  songAd = [];

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public authAppService: AuthService,
    public appService: AppService,
    public http: HttpClient,
    public webService: WebService,
    public toastr: ToastrService,
    public spinner: NgxSpinnerService,
    public location: Location
  ) {
    super(
      router,
      route,
      authAppService,
      appService,
      http,
      webService,
      toastr,
      spinner,
      location
    );
  }

  ngOnInit(): void {
    this.loadEvent();
    this.initAd();
    this.getSongRequestByEvent();
  }

  onDestroy(): void {
    
  }

  refreshPage(): void {
        
  }

  ngAfterViewInit(): void { }

  initAd() {
    this.loadExternalScript(
      "https://securepubads.g.doubleclick.net/tag/js/gpt.js"
    ).then(() => {
      window.googletag = window.googletag || { cmd: [] };

    });
  }

  async getSongRequestByEvent() {
    if (this.event.key === undefined) {
      return;
    }
    this.spinner.show();
    var root = await this.webService.getSongRequestByEvent(this.event.key);
    this.spinner.hide();
    if (root.code === 0) {
      let i = 0;
      let adId = 0;
      root.data.forEach((element: SongRequestModel) => {
        if (element.requestType == "1") {
          if (element.state == "2") {
            i++;
            if (i == 6) {
              adId++;
              let adIdString = "ad_" + Helpers.random() + "_" + adId;
              const s = { IsAd: true, AdId: adIdString } as SongRequestModel;
              this.songs.push(s);
              this.songAd.push(adIdString);
              i = 0;
            } else {
              element.IsAd = false;
              this.songs.push(element);
            }
          }
        } else if (element.requestType == "4") {
          this.genres.push(element);
        } else if (element.requestType == "5") {
          this.bpms.push(element);
        } else if (element.requestType == "6") {
          this.moods.push(element);
        }
      });
      if (
        this.genres.length == 0 &&
        this.moods.length == 0 &&
        this.bpms.length == 0
      ) {
        this.hasTopPart = false;
      } else {
        this.hasTopPart = true;
        if (this.genres.length == 0) {
          this.hasGenre = false;
        } else {
          this.hasGenre = true;
          let result = Helpers.groupBy(this.genres, (x) => x.requestValue);
          let temp = [];
          for (let key in result) {
            temp.push({ length: result[key].length, value: key });
          }
          const sortedArray = Helpers.sortArrayOfObjects(temp, "length", "desc")
          this.genreTitle = sortedArray[0].value;
          this.genrePercent = (sortedArray[0].length / this.genres.length) * 100;
        }

        if (this.bpms.length == 0) {
          this.hasBPM = false;
        } else {
          this.hasBPM = true;

          let result = Helpers.groupBy(this.bpms, (x) => x.requestValue);
          let temp = [];
          for (let key in result) {
            temp.push({ length: result[key].length, value: key });
          }
          const sortedArray = Helpers.sortArrayOfObjects(temp, "length", "desc")
          this.bpmTitle = sortedArray[0].value + " bpm";
          this.bpmPercent = (sortedArray[0].length / this.bpms.length) * 100;
        }

        if (this.moods.length == 0) {
          this.hasMood = false;
        } else {
          this.hasMood = true;

          let result = Helpers.groupBy(this.moods, (x) => x.requestValue);
          let temp = [];
          for (let key in result) {
            temp.push({ length: result[key].length, value: key });
          }
          const sortedArray = Helpers.sortArrayOfObjects(temp, "length", "desc")
          this.moodTitle = sortedArray[0].value;
          this.moodPercent = (sortedArray[0].length / this.moods.length) * 100;
        }
      }

      // this.loadAd();
    } else {
      this.hasTopPart = false;
      this.toastr.error("Error", root.msg);
    }
  }
}
