import { HttpClient } from "@angular/common/http";
import {
  Component,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalDirective } from "ngx-bootstrap/modal";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../../auth/AuthService";
import { AppService } from "../../services/app.service";
import { Location } from "@angular/common";
import { Constants } from "../../helpers/Constants";
import { LogicComponent } from "./logic.component";
import { WebService } from "../../webServices/WebService";

declare var $: any;
declare var window: any;


@Component({
  template: ''
})
export abstract class TipComponent extends LogicComponent {

  @ViewChild("premiumModal") public premiumModal: ModalDirective;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public http: HttpClient,
    public webService: WebService,
    public toastr: ToastrService,
    public spinner: NgxSpinnerService,
    public authAppService: AuthService,
    public appService: AppService,
    public location: Location
  ) {
    super(
      router,
      route,
      http,
      webService,
      toastr,
      spinner,
      authAppService,
      appService,
      location
    );
  }

  tipDJ() {
    this.requestType = "2";
    localStorage.setItem(Constants.SETTINGS_REQUEST_TYPE, this.requestType);
    localStorage.setItem(
      Constants.SETTINGS_LATEST_REQUEST,
      Date.now().toString()
    );
    this.requestType = localStorage.getItem(Constants.SETTINGS_REQUEST_TYPE);
    if (this.amount === 0) {
      return;
    }

    let packageId = "";
    let packageName = "";
    const rightAmount = this.amount / 100;
    packageId = rightAmount + "";
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    packageName = formatter.format(rightAmount);

    this.requestSong(
      "",
      "",
      this.requesterName,
      "",
      "",
      this.event.key,
      this.event.eventName,
      "",
      "",
      "",
      "",
      packageName,
      packageId,
      this.event.uid,
      this.event.djEmail,
      "",
      this.requestType
    );
  }

  TipModalBtnClicked(event: string) {
    switch (event) {
      case "btnTip1Clicked":
        if (this.sessionType == 4) {
          this.amount = 300;
          this.donateModalComponent.donateModal.hide();
        } else {
          this.amount = 500;
          this.tipModalComponent.tipModal.hide();
        }
        this.updateApplePayPrice();
        this.CreateCheckoutSessionAMZ();
        this.paymentModal.show();
        break;
      case "btnTip2Clicked":
        if (this.sessionType == 4) {
          this.amount = 500;
          this.donateModalComponent.donateModal.hide();
        } else {
          this.amount = 1000;
          this.tipModalComponent.tipModal.hide();
        }
        this.updateApplePayPrice();
        this.CreateCheckoutSessionAMZ();
        this.paymentModal.show();
        break;
      case "btnTip3Clicked":
        this.amount = 1500;
        if (this.sessionType == 4) {
          this.amount = 1000;
          this.donateModalComponent.donateModal.hide();
        } else {
          this.amount = 1500;
          this.tipModalComponent.tipModal.hide();
        }
        this.updateApplePayPrice();
        this.CreateCheckoutSessionAMZ();
        this.paymentModal.show();
        break;
      case "btnTipCustomTipClicked":
        if (this.sessionType == 4) {
          if (
            this.donateModalComponent.customTip >= 3 &&
            this.donateModalComponent.customTip <= 1000
          ) {
            this.amount = this.donateModalComponent.customTip * 100;
            this.donateModalComponent.donateModal.hide();
            this.updateApplePayPrice();
            this.CreateCheckoutSessionAMZ();
            this.paymentModal.show();
          } else {
            this.toastr.show("Please enter from $3 to $1000");
          }
        } else {
          if (
            this.tipModalComponent.customTip >= 1 &&
            this.tipModalComponent.customTip <= 1000
          ) {
            this.amount = this.tipModalComponent.customTip * 100;
            this.tipModalComponent.tipModal.hide();
            this.updateApplePayPrice();
            this.CreateCheckoutSessionAMZ();
            this.paymentModal.show();
          } else {
            this.toastr.show("Please enter from $1 to $1000");
          }
        }

        break;
      case "btnCancelTipModalClicked":
        // this.afterRequestModalComponent.modal.show();
        break;
    }
  }
}