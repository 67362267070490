import { HttpClient, HttpParams } from "@angular/common/http";
import {
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalDirective } from "ngx-bootstrap/modal";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../../auth/AuthService";
import { Constants } from "../../helpers/Constants";
import { AppService } from "../../services/app.service";
import { Location } from "@angular/common";
import { webService } from "../../webServices/api";
import { EventModel, RootEventModel } from "../../models/EventModel";
import { RootSongRequestModel, SongRequestModel } from "../../models/SongRequestModel";
import { SponsorModel } from "../../models/SponsorModel";
import { TipModalComponent } from "../modal/tip-modal/tip-modal.component";
import { SongModel } from "../../models/SongModel";
import { Helpers } from "../../helpers/Helpers";
import { StandardSessionComponent } from "../detail/standard/standard.component";
import { DJSessionComponent } from "../detail/djSession/djsession.component";
import { v4 as uuidv4 } from 'uuid';
import { UpNextComponent } from "../firstFriday/ui/upnext.component";
import { DonateModalComponent } from "../modal/donate-modal/donate-modal.component";
import { PerformanceComponent } from "../firstFriday/ui/performance.component";
import { CommonModel } from "../../models/CommonModel";
import { LocationModalComponent } from "../modal/locationModal/locationModal.component";
import { SearchSongModalComponent } from "../modal/searchSongModal/searchSongModal.component";
import { WebService } from "../../webServices/WebService";

declare var $: any;
declare let paypal: any;
declare var window: any;
declare var googletag: any;
declare let amazon: any;
declare let OffAmazonPayments: any;

@Component({
  template: ''
})
export abstract class BaseComponent implements OnDestroy {
  @ViewChild("askLocationModal") public askLocationModal: ModalDirective;
  @ViewChild("locationGoogleModal") public locationGoogleModal: ModalDirective;
  @ViewChild("shoutoutModal") public shoutoutModal: ModalDirective;
  @ViewChild("paymentModal") public paymentModal: ModalDirective;
  @ViewChild("marquee") public marquee: ElementRef;
  @ViewChild("createPartyModal") public createPartyModal: ModalDirective;

  @ViewChild(TipModalComponent) tipModalComponent: TipModalComponent;
  @ViewChild(DonateModalComponent) donateModalComponent: DonateModalComponent;

  @ViewChild(StandardSessionComponent) standardSessionComponent: StandardSessionComponent;
  @ViewChild(DJSessionComponent) dJSessionComponent: DJSessionComponent;
  @ViewChild(UpNextComponent) upNextComponent: UpNextComponent;
  @ViewChild(PerformanceComponent) performanceComponent: PerformanceComponent;
  @ViewChild(LocationModalComponent) locationModalComponent: LocationModalComponent;
  @ViewChild(SearchSongModalComponent) searchSongModalComponent: SearchSongModalComponent;

  sessionType = 1;

  isPremium = false;
  isCollectPhoneNumbers_party = "2";
  isOnlyPickSongPlaylists_party = "2";

  amount: number = 1000;
  squarePayments: any;
  applePay: any;
  events = [];
  songs = [];
  playlists = [];
  breakSongs = [];
  currentPlaylistSongs = [];
  locations = [];
  currentRequestedKey = "";
  key: string;
  event: EventModel;
  djName: string;
  requestType = "1";
  hasSponsor: boolean;
  shoutoutSponsor: SponsorModel = {
    id: "",
    title: "",
    subTitle: "",
    thumb: "",
    link: "",
  };
  addressName = "";
  address: any;
  shortlyAddress = "";
  currentPlaceId = "";
  lat: any;
  lng: any;
  noLocation = false;
  requesterName = "";

  searchKeyword: string;
  createPartyKeyword: string;
  isKaraoke = false;
  isTipping = true;
  isCollectPhoneNumbers = false;
  isCollectEmails = false;
  isSearchSong = true;
  isOnBoard = false;

  userRequestType = ""
  isPerformance = false;

  payloadJSON: string;
  signature: string;

  findLocationTitle = "Find Nearby DJ Session";
  findLocationDes =
    "Tell us your current location or a location near the session you’re looking for.";

  btnWrongVenueClickedEvent: any;

  spotifyPlaylistId = "";
  spotifyPlaylistKey = "";
  isEmptyPlaylist = true;
  startPlaylistMinutes = 0;
  isWhitelisted = false;
  hasSYBPlaylist = false;
  lastUpdated = 0;

  tipLink = "";
  socialLink = "";

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public authAppService: AuthService,
    public appService: AppService,
    public http: HttpClient,
    public webService: WebService,
    public toastr: ToastrService,
    public spinner: NgxSpinnerService,
    public location: Location
  ) {

  }

  ngOnDestroy(): void {
    this.btnWrongVenueClickedEvent.unsubscribe();
    this.onDestroy()
  }

  abstract onDestroy(): void

  AfterRequestModalBtnClicked(event: string) {
    switch (event) {
      case "btnYesClicked":
        break;
      case "btnCancelClicked":
        this.location.back();
        break;
    }
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  loadEvent() {
    var key = localStorage.getItem(Constants.EVENT_KEY);
    var eventName = localStorage.getItem(Constants.EVENT_NAME);
    var uid = localStorage.getItem(Constants.EVENT_UID);
    var djEmail = localStorage.getItem(Constants.EVENT_DJEMAIL);
    var djName = localStorage.getItem(Constants.EVENT_DJNAME);
    var profileUrl = localStorage.getItem(Constants.EVENT_PROFILE_URL);
    var venue = localStorage.getItem(Constants.EVENT_VENUE);
    var isKaraoke = localStorage.getItem(Constants.EVENT_IS_KARAOKE);
    var isTipping = localStorage.getItem(Constants.EVENT_IS_TIPPING);
    var isCollectEmails = localStorage.getItem(Constants.EVENT_IS_COLLECTS_EMAILS);
    var isCollectPhoneNumbers = localStorage.getItem(Constants.EVENT_IS_COLLECTS_PHONE_NUMBERS);

    this.event = {
      key: key,
      cover: "",
      eventName: eventName,
      genres: "",
      address: "",
      venue: venue,
      uid: uid,
      djEmail: djEmail,
      djName: djName,
      profileUrl: profileUrl,
      isKaraoke: isKaraoke,
      isTipping: isTipping,
      enable: "",
      isCollectEmails: isCollectEmails,
      isCollectPhoneNumbers: isCollectPhoneNumbers
    };

    if (this.event.venue === "" || typeof this.event.venue === "undefined") {
      this.appService.Venue = " UNKNOWN";
    } else {
      this.appService.Venue = " " + this.event.venue;
    }

    if (isTipping == "1") {
      this.isTipping = true;
    } else {
      this.isTipping = false;
    }
    this.appService.IsTipping = this.isTipping;

    if (this.event.isKaraoke == "1") {
      this.isKaraoke = true;
    } else {
      this.isKaraoke = false;
    }

    if (this.event.isCollectPhoneNumbers == "1") {
      this.isCollectPhoneNumbers = true;
    } else {
      this.isCollectPhoneNumbers = false;
    }

    if (this.event.isCollectEmails == "1") {
      this.isCollectEmails = true;
    } else {
      this.isCollectEmails = false;
    }
  }

  calculateDistanceForGoogleGeo(lat1: any, lng1: any, lat2: any, lng2: any): number {
    function deg2rad(deg) { return deg * (Math.PI / 180); }
    function square(x) { return Math.pow(x, 2); }
    var r = 6371;
    lat1 = deg2rad(lat1);
    lat2 = deg2rad(lat2);
    var lat_dif = lat2 - lat1;
    var lng_dif = deg2rad(lng2 - lng1);
    var a = square(Math.sin(lat_dif / 2)) + Math.cos(lat1) * Math.cos(lat2) * square(Math.sin(lng_dif / 2));
    var d = 2 * r * Math.asin(Math.sqrt(a));
    return d;
  }

  rearrange(): void {
    var temps = this.locations;
    this.locations = [];
    temps.sort((a, b) => b.distance - a.distance);;
    temps.forEach(s => {
      this.locations.push(s);
    });
    this.currentPlaceId = this.locations[0].place_id;
    this.appService.Venue = this.locations[0].formatted_address;
    this.address = this.locations[0].formatted_address;
    var addresses = this.locations[0].formatted_address.split(",");
    if (addresses && addresses.length > 0) {
      this.shortlyAddress = addresses[0];
    } else {
      this.shortlyAddress = this.address;
    }
    localStorage.setItem(Constants.SETTINGS_ADDRESS, this.address);
    this.locationModalComponent.updateInfo(this.addressName, this.address);
    this.nearbyParty();
    this.nearbySearch();
  }

  nearbySearch() {
    this.http.get<any>(webService.GoogleNearbySearch + `?lat=${this.lat}&lng=${this.lng}`).subscribe((root) => {
      if (root && root.data && root.data.length > 0) {
        var placeIds = "";
        root.data.forEach(place => {
          var distance = this.calculateDistanceForGoogleGeo(this.lat, this.lng, place.location.latitude, place.location.longitude);
          if (this.locations.length > 0) {
            if (distance <= 0.01) {
              this.appService.Venue = place.displayName.text;
              this.addressName = place.displayName.text;
              this.address = place.formattedAddress;
              this.currentPlaceId = place.id;

              this.locationModalComponent.updateInfo(this.addressName, this.address);
            }
          }
          place.distance = (distance * 0.621371192).toFixed(2) + " miles";
          place.realDistance = distance;
          place.isActive = false;
          place.isParty = false;
          placeIds += place.id + ",";;
          this.locationModalComponent.nearByLocations.push(place);
        });
        this.reorderLocation();
        if (placeIds !== "") {
          placeIds = placeIds.substring(0, placeIds.length - 1);
        }
        this.getPlaylistByPlaceIds(placeIds);
      }
    });
  }

  async nearbyParty() {
    var root = await this.webService.NearbyParty(this.lat, this.lng);
    if (root && root.data && root.data.length > 0) {
      var placeIds = "";
      root.data.forEach(place => {
        place.id = place.placeId;
        place.displayName = {};
        place.displayName.text = place.eventName;
        place.formattedAddress = place.address;

        place.location = {};
        place.location.latitude = place.coordinates._latitude;
        place.location.longitude = place.coordinates._longitude;

        var distance = this.calculateDistanceForGoogleGeo(this.lat, this.lng, place.coordinates._latitude, place.coordinates._longitude);
        if (distance <= 0.02) {
          this.appService.Venue = place.displayName.text;
          this.addressName = place.displayName.text;
          this.address = place.formattedAddress;
          this.currentPlaceId = place.id;

          this.createPartyModal.hide();

          this.locationModalComponent.updateInfo(this.addressName, this.address);
          this.locationModalComponent.thisModal.show();
        }

        place.distance = (distance * 0.621371192).toFixed(2) + " miles";
        place.realDistance = distance;
        place.isParty = true;
        placeIds += place.id + ",";
        this.locationModalComponent.nearByLocations.push(place);
      });
      this.reorderLocation();
      if (placeIds !== "") {
        placeIds = placeIds.substring(0, placeIds.length - 1);
      }
      this.getPlaylistByPlaceIds(placeIds);
    }
  }

  reorderLocation() {
    this.locationModalComponent.nearByLocations.sort((a, b) => a.realDistance - b.realDistance);
  }

  convertLatLngToAddress() {
    this.http.get<any>(webService.GoogleGeocode(this.lat, this.lng)).subscribe((root) => {
      if (root && root.results && root.results.length > 0) {
        const found = this.locations.find((obj) => {
          return obj.place_id === root.results[0].place_id;
        });
        var distance = this.calculateDistanceForGoogleGeo(this.lat, this.lng, root.results[0].geometry.location.lat, root.results[0].geometry.location.lng);
        if (!found) {
          root.results[0].distance = distance;
          this.locations.push(root.results[0]);
          this.rearrange();
          this.getPlaylistByPlaceId(this.locations[0].place_id);
        } else {
          this.getPlaylistByPlaceId(found.place_id);
        }
      } else {
        this.appService.Venue = "UNKNOW";
      }
    });
  }

  onVenueSelect(item: EventModel): void {
    this.key = item.key;
    this.locationModalComponent.thisModal.hide();
    this.router.navigate([""], { queryParams: { key: item.key } });
  }

  getSession(isFirst: boolean, fromHelpRequest: boolean) {
    this.showLoading();
    this.events = [];
    const param =
      "?latitude=" + this.lat + "&longitude=" + this.lng + "&filterType=1";
    this.http
      .get<RootEventModel>(webService.getEvent + param)
      .subscribe((root) => {
        this.hideLoading();
        if (root.code === 0) {
          if (root.data.length === 0) {
            if (fromHelpRequest) {
              this.createPartyModal.show();
            } else {
              console.log('getSession');
              this.askLocationModal.show();
            }
          } else {
            if (!isFirst) {
              this.locationModalComponent.thisModal.show();
              this.askLocationModal.hide();
              root.data.forEach((element) => {
                this.events.push(element);
              });
            } else {
              this.key = root.data[0].key;
              this.router.navigate([""], { queryParams: { key: this.key } });
            }
          }
        } else {
          this.toastr.error("Error", root.msg);
        }
      });
  }

  songRequestedKaraoke(data) {
    const speed = (data.length + 1) * 3;
    $("#marqueeP").css(
      "animation",
      `sidescroll ${speed}s linear infinite`
    );
    $("#tickerRefreshPlaylist").css(
      "height", "0px"
    );
    $("#footer").css(
      "height", "50px"
    );
    this.marquee.nativeElement.innerHTML = "";

    this.addSponsorTicker();

    let i = 0;
    data.forEach((element) => {
      if (
        element.requestType == "1" ||
        element.requestType == "2" ||
        element.requestType == "3"
      ) {
        i++;
        if (i >= 4) {
          this.addSponsorTicker();
          i = 0;
        }
        let name: string;
        if (
          element.firstName === "Anonymous" ||
          element.firstName === ""
        ) {
          name = "Someone";
        } else {
          name = element.firstName;
        }

        if (element.requestType === "1" || element.requestType === "2") {
          const img = element.songThumb;
          const htmlImg =
            '<img alt="" class="songThumbMarquee center-crop" src="' +
            img +
            '"' +
            "</img>";
          this.marquee.nativeElement.insertAdjacentHTML(
            "beforeend",
            htmlImg
          );
        }

        if (element.requestType === "1" || element.requestType === "2") {
          const html3 =
            '<a class="requestBoldSpan">' + element.songName + "</a>";
          this.marquee.nativeElement.insertAdjacentHTML(
            "beforeend",
            html3
          );

          if (name !== "Someone") {
            const html2 = '<a class="requestedSpan">requested by</a>';
            this.marquee.nativeElement.insertAdjacentHTML(
              "beforeend",
              html2
            );

            const html1 = '<a class="requestBoldSpan">' + name + "</a>";
            this.marquee.nativeElement.insertAdjacentHTML(
              "beforeend",
              html1
            );
          } else {
            const html2 = '<a class="requestedSpan">-</a>';
            this.marquee.nativeElement.insertAdjacentHTML(
              "beforeend",
              html2
            );

            const html1 =
              '<a class="requestBoldSpan">' + element.songArtist + "</a>";
            this.marquee.nativeElement.insertAdjacentHTML(
              "beforeend",
              html1
            );
          }
        } else if (element.requestType === "3") {
          if (element.state === "2") {
            if (name !== "Someone") {
              const html1 = '<a class="requestBoldSpan">' + name + "</a>";
              this.marquee.nativeElement.insertAdjacentHTML(
                "beforeend",
                html1
              );

              const html2 = '<a class="requestedSpan">said,</a>';
              this.marquee.nativeElement.insertAdjacentHTML(
                "beforeend",
                html2
              );
            }
            const html3 =
              '<a class="requestBoldSpan">' +
              '"' +
              element.requestValue +
              '"' +
              "</a>";
            this.marquee.nativeElement.insertAdjacentHTML(
              "beforeend",
              html3
            );
          }
        }
        this.marquee.nativeElement.insertAdjacentHTML(
          "beforeend",
          '<a class="bulletSpan">&#8226;</a>'
        );
      }
    });
  }

  generateUUID(): string {
    var savedUUID = localStorage.getItem(
      Constants.SETTINGS_UUID
    );
    if (savedUUID === undefined || savedUUID === null) {
      let uuid = uuidv4();
      localStorage.setItem(Constants.SETTINGS_UUID, uuid);
      savedUUID = uuid;
    }

    return savedUUID;
  }

  songRequestedFirstFriday(data: [SongRequestModel]) {
    let result = Helpers.groupBy(data, (x) => x.key);
    this.songs = [];
    let temp = [];
    for (let key in result) {
      var songFirst = result[key][0];
      var noRequested = songFirst.noRequested;
      noRequested += result[key].length - 1;
      if (isNaN(noRequested))
        noRequested = 0;
      songFirst.noRequested = noRequested;
      temp.push(songFirst);
    }
    var tempSongs = [];
    var uuid = this.generateUUID();
    temp.forEach((element) => {
      var upvoted = false;
      if (element.uuids.includes(uuid)) {
        upvoted = true;
      }
      const s = {
        key: element.key, Uri: element.songKey,
        TrackId: element.songKey, Artist: element.songArtist,
        Thumb: element.songThumb, Title: element.songName,
        Enable: true, NoRequested: element.noRequested,
        Requester: element.firstName + " " + element.lastName,
        AppleMusic: element.link_apple_music,
        UUID: element.uuid, Upvoted: upvoted, Time: element.time,
        Explicit: element.explicit,
        RequesterText: "Requested by " + element.firstName + " " + element.lastName
      } as SongModel;
      tempSongs.push(s);
    });
    tempSongs.sort((a, b) => a.Time - b.Time);
    tempSongs.sort((a, b) => b.NoRequested - a.NoRequested);
    tempSongs.forEach(s => {
      this.songs.push(s);
    });
    if (this.isPerformance) {
      this.performanceComponent.updateSongs(this.songs);
    } else {
      this.upNextComponent.updateSongs(this.songs);
    }
  }

  songRequested(data: [SongRequestModel]) {
    let result = Helpers.groupBy(data, (x) => x.songKey);
    let temp = [];
    this.songs = [];
    for (let key in result) {
      var songFirst = result[key][0];
      var noRequested = songFirst.noRequested;
      noRequested += result[key].length - 1;
      if (isNaN(noRequested))
        noRequested = 0;
      songFirst.noRequested = noRequested;
      temp.push(songFirst);
    }
    var tempSongs = [];
    var uuid = this.generateUUID();
    temp.forEach((element) => {
      var upvoted = false;
      var downvoted = false;
      if (element.uuids.includes(uuid)) {
        upvoted = true;
      }
      if (element.downVoteUuids.includes(uuid)) {
        downvoted = true;
      }
      var isExplicit = element.explicit == "0" ? false : true

      const s = {
        key: element.key, Uri: element.songKey,
        TrackId: element.songKey, Artist: element.songArtist,
        Thumb: element.songThumb, Title: element.songName,
        Enable: true, NoRequested: element.noRequested,
        Requester: element.firstName + " " + element.lastName,
        AppleMusic: element.link_apple_music,
        UUID: element.uuid, Upvoted: upvoted, Downvoted: downvoted,
        Time: element.time,
        ItemType: 1,
        Duration: Number(element.duration_ms),
        Explicit: isExplicit,
        RequesterText: "Requested by " + element.Requester
      } as SongModel;
      tempSongs.push(s);
    });
    tempSongs.sort((a, b) => a.Time - b.Time);
    tempSongs.sort((a, b) => b.NoRequested - a.NoRequested);

    var countDuration = 0;
    var hasSeparator = false;
    tempSongs.forEach(s => {
      countDuration += s.Duration;
      if (!hasSeparator && countDuration > 1500000) {
        const separator = {
          Duration: 0,
          ItemType: 2
        } as SongModel;
        this.songs.push(separator);
        hasSeparator = true;
      }
      this.songs.push(s);
    });
    this.standardSessionComponent.updateSongs(this.songs);
  }

  GetSongRequestByKaraokeEvent() {
    if (this.key === undefined) {
      return;
    }
    this.showLoading();
    const param = "?eventKey=" + this.key;
    this.http
      .get<RootSongRequestModel>(webService.GetSongRequestByKaraokeEvent + param)
      .subscribe((root) => {
        this.hideLoading();
        if (root.code === 0) {
          this.songRequestedKaraoke(root.data);
        } else {
          this.toastr.error("Error", root.msg);
        }
      });
  }

  async GetSongRequestByStandard(isSongRequest: Boolean) {
    this.showLoading();
    const root = await this.webService.GetSongRequestByStandard(this.currentPlaceId, this.addressName);
    this.hideLoading();
    if (root.code === 0) {
      if (root.data.length == 0) {
        this.searchSongModalComponent.updateStaticModal(true);
        this.searchSongModalComponent.thisModal.show();
      } else {
        this.searchSongModalComponent.thisModal.hide();
        if (isSongRequest
          && root.data.length == Constants.NUMBER_OF_SONG_REQUEST
          && this.startPlaylistMinutes == 0) {
          this.StartSpotifyPlaylist();
        }
      }
      this.songRequested(root.data);
    } else {
      this.toastr.error("Error", root.msg);
    }
  }

  StartSpotifyPlaylist() {
    var t = Date.parse(Date());
    var minutes = Math.floor((t / 1000 / 60) % 60)
    var key = localStorage.getItem(Constants.SETTINGS_SPOTIFY_PLAYLIST_KEY);

    this.startPlaylistMinutes = minutes;
    localStorage.setItem(Constants.SETTINGS_SPOTIFY_PLAYLIST_MIN, minutes.toString());

    if (key) {
      const params = new HttpParams()
        .set("key", key)
        .set("spotifyPlaylistId", this.spotifyPlaylistId)
        .set("placeId", this.currentPlaceId)
        .set("min", minutes.toString());

      this.http
        .post(webService.StartPlaylist, params)
        .subscribe((root: CommonModel) => {
          console.log('StartSpotifyPlaylist');
        });
    }
  }

  getPlaylistByPlaceId(placeId) {
    const param = "?placeId=" + placeId;
    this.http
      .get<any>(webService.getPlaylistByPlaceId + param)
      .subscribe((root) => {
        if (root.code === 0) {
          var lastUpdated = root.data.lastUpdated;
          this.lastUpdated = lastUpdated;
          if (lastUpdated) {
            var now = Date.now();
            if (now - lastUpdated < 3600000) {
              this.locationModalComponent.isActive = true;
            } else {
              this.locationModalComponent.isActive = false;
            }
          }
        }
      });
  }

  getPlaylistByPlaceIds(placeIds) {
    const param = "?placeIds=" + placeIds;
    this.http
      .get<any>(webService.getPlaylistByPlaceIds + param)
      .subscribe((root) => {
        if (root.code === 0) {
          var now = Date.now();
          root.data.forEach(playlist => {
            var lastUpdated = playlist.lastUpdated;
            if (lastUpdated) {
              if (now - lastUpdated < 3600000) {
                const found = this.locationModalComponent.nearByLocations.find((obj) => {
                  return obj.id === playlist.placeId;
                });
                if (found) {
                  found.isActive = true;
                }
              }
            }
          });
        }
      });
  }

  GetSpotifyPlaylist() {
    if (this.currentPlaceId == undefined
      || this.currentPlaceId == null
      || this.currentPlaceId == "")
      return;
    this.showLoading();
    const param = "?placeId=" + this.currentPlaceId + "&businessName=" + this.addressName;
    this.http
      .get<any>(webService.GetSpotifyPlaylist + param)
      .subscribe(async (root) => {
        this.hideLoading();
        if (root.code === 0 && Object.keys(root.data).length != 0) {
          this.spotifyPlaylistId = root.data.id;
          this.spotifyPlaylistKey = root.data.key;
          var isNew = root.data.isNew;
          if (isNew) {
            this.isEmptyPlaylist = true;
          } else {
            this.isEmptyPlaylist = false;
          }
          var min = root.data.min;
          if (min != undefined && min != 0) {
            this.isWhitelisted = false;
            this.standardSessionComponent.isStartedPlaylist = true;
            this.startPlaylistMinutes = Math.round(Number(min));
            this.standardSessionComponent.removeItemType3FromSongs();
          } else {
            this.isWhitelisted = true;
            this.standardSessionComponent.setIsStartedPlaylist();
            this.startPlaylistMinutes = 0;
          }

          this.isOnBoard = true;

          this.GetSongRequestTicker();
          if (root.data.playlistSYBId != "") {
            this.hasSYBPlaylist = true;
          }
          localStorage.setItem(Constants.SETTINGS_SPOTIFY_PLAYLISTID, this.spotifyPlaylistId);
          localStorage.setItem(Constants.SETTINGS_SPOTIFY_PLAYLIST_KEY, this.spotifyPlaylistKey);
          localStorage.setItem(Constants.SETTINGS_SYB_PLAYLISTID, root.data.playlistSYBId);
          localStorage.setItem(Constants.SETTINGS_SPOTIFY_PLAYLIST_MIN, this.startPlaylistMinutes.toString());
          this.refreshPage();
          this.GetSpotifyPlaylistSuggest(this.spotifyPlaylistKey);
        } else {
          this.spotifyPlaylistId = "";
          this.spotifyPlaylistKey = "";
          localStorage.setItem(Constants.SETTINGS_SPOTIFY_PLAYLISTID, this.spotifyPlaylistId);
          localStorage.setItem(Constants.SETTINGS_SPOTIFY_PLAYLIST_KEY, this.spotifyPlaylistKey);
          await this.GetPlaylistSuggestion();
        }
      });
  }

  GetSpotifyPlaylistSuggest(playlistId: string) {
    this.breakSongs = [];
    const param = "?id=" + playlistId;
    this.http
      .get<any>(webService.GetSpotifyPlaylistSuggest + param)
      .subscribe(async (root) => {
        if (root.code === 0) {
          root.data.forEach((track) => {
            let artistName = "";
            let imageUrl = "";
            if (track.album.images != null && track.album.images.length > 0) {
              imageUrl = track.album.images[0].url;
            }
            track.artists.forEach((artist) => {
              artistName += artist.name + ", ";
            });

            artistName = artistName.trim().slice(0, -1);
            track.imageUrl = imageUrl;
            track.artistName = artistName;
            this.breakSongs.push(track);
          });
          if (this.breakSongs.length == 0) {
            await this.GetPlaylistSuggestion();
          } else {
            this.isSearchSong = false;
          }
        }
      });
  }

  async GetPlaylistSuggestion() {
    if (this.playlists.length > 0)
      return;
    var root = await this.webService.GetPlaylistSuggestion();
    if (root != null && root.code == 0) {
      root.data.forEach((track) => {
        let artistName = "";
        let imageUrl = "";
        if (track.album.images != null && track.album.images.length > 0) {
          imageUrl = track.album.images[0].url;
        }
        track.artists.forEach((artist) => {
          artistName += artist.name + ", ";
        });

        artistName = artistName.trim().slice(0, -1);
        track.imageUrl = imageUrl;
        track.artistName = artistName;
        this.breakSongs.push(track);
      });
      if (this.breakSongs.length != 0) {
        this.isSearchSong = false;
        this.isPremium = false;
      }
    }
  }

  GetSongRequestTicker() {
    this.showLoading();
    const param = "?placeId=" + this.currentPlaceId + "&businessName=" + this.addressName;
    this.http
      .get<RootSongRequestModel>(webService.GetSongRequestByStandardForTicker + param)
      .subscribe((root) => {
        this.hideLoading();
        if (root.code == 0) {
          var data = [];
          this.currentPlaylistSongs = root.data;
          var s: SongRequestModel;
          if (this.isPremium) {
            console.log(this.tipLink);
            if (this.tipLink != undefined && this.tipLink != "") {
              s = { requestType: "4", requestValue: `Tip the DJ`, songThumb: "assets/img/ic_tip_dj.png" } as SongRequestModel;
              data.push(s);
            }

            if (this.socialLink != undefined && this.socialLink != "") {
              s = { requestType: "5", requestValue: `Follow the DJ`, songThumb: "assets/img/ic_follow_dj.png" } as SongRequestModel;
              data.push(s);
            }
          }
          if (root.data.length > 0) {
            s = { requestType: "3", requestValue: "CURRENT PLAYLIST " } as SongRequestModel;
          } else {
            if (this.standardSessionComponent.isStartedPlaylist) {
              if (this.addressName == "") {
                s = { requestType: "3", requestValue: `When the countdown hits zero, "${this.shortlyAddress}" playlist will update with the top song requests` } as SongRequestModel;
              } else {
                s = { requestType: "3", requestValue: `When the countdown hits zero, "${this.addressName}" playlist will update with the top song requests` } as SongRequestModel;
              }
            } else {
              if (this.addressName == "") {
                s = { requestType: "3", requestValue: `${this.shortlyAddress} playlist will start after ${Constants.NUMBER_OF_SONG_REQUEST} song requests` } as SongRequestModel;
              } else {
                s = { requestType: "3", requestValue: `${this.addressName} playlist will start after ${Constants.NUMBER_OF_SONG_REQUEST} song requests` } as SongRequestModel;
              }
            }
          }
          data.push(s);
          root.data.forEach(element => {
            data.push(element);
          });
          this.songRequestedTicker(data);
        }
      });
  }

  songRequestedTicker(data) {
    if (this.standardSessionComponent.isStartedPlaylist) {
      $("#tickerRefreshPlaylist").css({
        "height": "50px",
        "display": "block",
        "visibility": "visible"
      });
      $("#marquee").css(
        "height", "50px"
      );
      $("#footer").css(
        "height", "100px"
      );
    } else {
      $("#tickerRefreshPlaylist").css({
        "height": "0px",
        "display": "none",
        "visibility": "hidden !important",
      });
      $("#footer").css(
        "height", "50px"
      );
    }

    const speed = (data.length + 1) * 4;
    $("#marqueeP").css(
      "animation",
      `sidescroll ${speed}s linear infinite`
    );

    this.marquee.nativeElement.innerHTML = "";

    data.forEach((element) => {
      if (
        element.requestType == "1" ||
        element.requestType == "2" ||
        element.requestType == "3" ||
        element.requestType == "4" ||
        element.requestType == "5"
      ) {
        let name: string;
        if (
          element.firstName === "Anonymous" ||
          element.firstName === ""
        ) {
          name = "Someone";
        } else {
          name = element.firstName;
        }

        if (element.requestType === "1" || element.requestType === "2" || element.requestType === "4" || element.requestType === "5") {
          const img = element.songThumb;
          const htmlImg =
            '<img alt="" class="songThumbMarquee center-crop" src="' +
            img +
            '"' +
            "</img>";
          this.marquee.nativeElement.insertAdjacentHTML(
            "beforeend",
            htmlImg
          );
        }

        if (element.requestType === "1" || element.requestType === "2") {
          const html3 =
            '<a class="requestBoldSpan" (click)="btnPlaylistClicked();">' + element.songName + "</a>";
          this.marquee.nativeElement.insertAdjacentHTML(
            "beforeend",
            html3
          );

          if (name !== "Someone") {
            const html2 = '<a class="requestedSpan">requested by</a>';
            this.marquee.nativeElement.insertAdjacentHTML(
              "beforeend",
              html2
            );

            const html1 = '<a class="requestBoldSpan">' + name + "</a>";
            this.marquee.nativeElement.insertAdjacentHTML(
              "beforeend",
              html1
            );
          } else {
            const html2 = '<a class="requestedSpan">-</a>';
            this.marquee.nativeElement.insertAdjacentHTML(
              "beforeend",
              html2
            );

            const html1 =
              '<a class="requestBoldSpan">' + element.songArtist + "</a>";
            this.marquee.nativeElement.insertAdjacentHTML(
              "beforeend",
              html1
            );
          }
        } else if (element.requestType === "3") {
          const html3 =
            '<a class="requestBoldSpan">' +
            element.requestValue +
            "</a>";
          this.marquee.nativeElement.insertAdjacentHTML(
            "beforeend",
            html3
          );
        } else if (element.requestType === "4") {
          const html3 =
            '<a href=' + this.tipLink + ' target="_blank" class="requestBoldSpan">' +
            element.requestValue +
            "</a>";
          this.marquee.nativeElement.insertAdjacentHTML(
            "beforeend",
            html3
          );
        } else if (element.requestType === "5") {
          const html3 =
            '<a  href=' + this.socialLink + ' target="_blank"  class="requestBoldSpan">' +
            element.requestValue +
            "</a>";
          this.marquee.nativeElement.insertAdjacentHTML(
            "beforeend",
            html3
          );
        }
        this.marquee.nativeElement.insertAdjacentHTML(
          "beforeend",
          '<a class="bulletSpan">&#8226;</a>'
        );
      }
    });
  }

  GetSpotifyPlaylistAndGoTo() {
    this.showLoading();
    const param = "?name=" + this.address;
    this.http
      .get<any>(webService.GetSpotifyPlaylist + param)
      .subscribe((root) => {
        this.hideLoading();
        if (root.code === 0) {
          this.spotifyPlaylistId = root.data.id;
          localStorage.setItem(Constants.SETTINGS_SPOTIFY_PLAYLISTID, root.data.id);
          localStorage.setItem(Constants.SETTINGS_SPOTIFY_PLAYLIST_KEY, root.data.key);
          localStorage.setItem(Constants.SETTINGS_SYB_PLAYLISTID, root.data.playlistSYBId);
          window.open("https://open.spotify.com/playlist/" + this.spotifyPlaylistId, "_blank");
        }
      });
  }

  GetPerformance() {
    this.showLoading();
    this.http
      .get<RootSongRequestModel>(webService.GetPerformance)
      .subscribe((root) => {
        this.hideLoading();
        if (root.code === 0) {
          this.songRequestedFirstFriday(root.data);
        } else {
          this.toastr.error("Error", root.msg);
        }
      });
  }

  GetSongRequest() {
    this.showLoading();
    this.http
      .get<RootSongRequestModel>(webService.GetSongRequest)
      .subscribe((root) => {
        this.hideLoading();
        if (root.code === 0) {
          this.songRequestedFirstFriday(root.data);
        } else {
          this.toastr.error("Error", root.msg);
        }
      });
  }

  addSponsorTicker() {
    if (this.hasSponsor) {
      const shoutoutSponsorTitle = this.shoutoutSponsor.title;
      const shoutoutSponsorThumb = this.shoutoutSponsor.thumb;

      if (shoutoutSponsorTitle !== "") {
        const htmlSponsorImg =
          '<img alt="" class="songThumbMarquee center-crop" src="' +
          shoutoutSponsorThumb +
          '"' +
          "</img>";
        this.marquee.nativeElement.insertAdjacentHTML(
          "beforeend",
          htmlSponsorImg
        );

        const htmlhtmlSponsorImg1 =
          '<a class="requestBoldSpan">' + shoutoutSponsorTitle + "</a>";
        this.marquee.nativeElement.insertAdjacentHTML(
          "beforeend",
          htmlhtmlSponsorImg1
        );

        this.marquee.nativeElement.insertAdjacentHTML(
          "beforeend",
          '<a class="bulletSpan">&#8226;</a>'
        );
      }
    }
  }

  loadExternalScript(scriptUrl: string) {
    return new Promise((resolve, reject) => {
      const scriptElement = document.createElement("script");
      scriptElement.src = scriptUrl;
      scriptElement.onload = resolve;
      document.body.appendChild(scriptElement);
    });
  }

  btnBackClicked() {
    this.location.back();
  }

  isSafari() {
    const chromeAgent = navigator.userAgent.indexOf("Chrome") > -1;
    let safariAgent = navigator.userAgent.indexOf("Safari") > -1;
    if (chromeAgent && safariAgent) {
      safariAgent = false;
    }

    return safariAgent;
  }

  btnCancelPaymentClicked() {
    this.paymentModal.hide();
  }

  btnMaybeLaterClicked() {
    this.tipModalComponent.tipModal.hide();
  }

  abstract refreshPage(): void;

  showLoading() {
    this.spinner.show('spinner1');
  }

  hideLoading() {
    this.spinner.hide('spinner1');
  }
}
