import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { SongModel } from "../../../models/SongModel";
import { PlaylistModel } from "../../../models/PlaylistModel";

declare var $: any;

@Component({
  selector: "DJSession",
  templateUrl: "./djsession.component.html",
})
export class DJSessionComponent implements OnInit {

  @Output() btnClicked = new EventEmitter<string>();
  @Output() songItemClicked = new EventEmitter<SongModel>();
  @Output() playlistItemClicked = new EventEmitter<PlaylistModel>();

  playlists = [];
  songs = [];
  selectedPlaylistTitle = "";
  noLocation = false;
  showPlaylistSessionTitle = true;

  constructor() { }

  ngOnInit(): void {

  }

  setPlaylist(playlist) {
    this.playlists = playlist;
    if (this.playlists.length == 0) {
      this.showPlaylistSessionTitle = false;
    }
  }

  onPlaylistSelect(item: PlaylistModel): void {
    this.playlists.forEach(function (value) {
      $("#" + value.key).css("border", "4px solid #ffffff00");
    });

    this.playlistItemClicked.emit(item);
  }

  onSongSelect(item: SongModel): void {
    if (item.key == "")
      return;
    this.songItemClicked.emit(item);
  }

  btnSearchSongClicked() {
    this.btnClicked.emit("btnSearchSongClicked");
  }
}
