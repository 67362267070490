import { HttpClient, HttpParams } from "@angular/common/http";
import { AfterViewInit, Component, NgZone, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../../auth/AuthService";
import { AppService } from "../../services/app.service";
import { Location } from '@angular/common';
import { AnimationOptions } from "ngx-lottie";
import { ModalDirective } from "ngx-bootstrap/modal";
import { webService } from "../../webServices/api";
import { Constants } from "../../helpers/Constants";
import { RequestedModel } from "../../models/RequestedModel";
import { AnimationItem } from "lottie-web";
import { PremiumComponent } from "../base/premium.component";
import { WebService } from "../../webServices/WebService";

declare var window: any;
declare var $: any;

@Component({
    templateUrl: 'bpm.component.html',
})
export class BPMComponent extends PremiumComponent implements OnInit, AfterViewInit {
    refreshPage(): void {
        
    }
    googlePlaceSelected(): void {
        
    }
    onRequestSongDonated(): void {
        
    }
    onUpvoteDonated(): void {
        
    }
    updateRequestASong(): void {
        
    }
    tipDJ(): void {
        
    }
    @ViewChild('checkingBPMRequestModal') public checkingBPMRequestModal: ModalDirective;

    options: AnimationOptions = {
        path: '/assets/wave.json'
    };

    bpmTemplate = new Map<number, string>();
    bpmTitle = "125 BPM";
    bpmSubtitle = "Pop, House, Techno, Rock";
    bpm = 125;
    isRunning = false;
    progressValue = 3;
    private animationItem: AnimationItem | null = null;

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        public authAppService: AuthService,
        public appService: AppService,
        public http: HttpClient,
        public webService: WebService,
        public toastr: ToastrService,
        public spinner: NgxSpinnerService,
        public location: Location,
        private ngZone: NgZone,
    ) {
        super(
            router,
            route,
            http,
            webService,
            toastr,
            spinner,
            authAppService,
            appService,
            location
          );
    }

    ngOnInit(): void {
        this.initBPMTemplate();
        this.loadEvent();
    }

    onDestroy(): void {

    }

    ngAfterViewInit(): void {
        this.handlePayment();
    }

    animationCreated(animationItem: AnimationItem): void {
        NgZone.assertInAngularZone();
        this.animationItem = animationItem;
        this.setSpeed(this.bpm / 60)
    }

    setSpeed(speed: number): void {
        this.ngZone.runOutsideAngular(() => {
            this.animationItem?.setSpeed(speed);
        });
    }

    btnSendRequestClicked() {
        this.progressValue = 3;
        this.isRunning = true;
        this.handleCheckCountdown(this.bpm.toString());
    }

    btnCancelRequestClicked() {
        this.isRunning = false;
        this.progressValue = 3;
        this.checkingBPMRequestModal.hide();
    }

    handleCheckCountdown(bpm: string): void {
        setTimeout(() => {
            this.progressValue--;
            if (this.progressValue < 0) {
                this.progressValue = 0;
                this.isRunning = false;
                this.checkingBPMRequestModal.hide();
                this.requestType = '5';
                this.requestBPM(
                    '',
                    '',
                    '',
                    '',
                    '',
                    this.event.key,
                    this.event.eventName,
                    '',
                    '',
                    '',
                    '',
                    '$0.00',
                    '0',
                    this.event.uid,
                    this.event.djEmail,
                    bpm,
                    this.requestType
                );
            }
            if (this.isRunning) {
                this.handleCheckCountdown(bpm);
            }
        }, 1000);
        this.checkingBPMRequestModal.show();
    }

    requestBPM(
        uid: string,
        email: string,
        firstName: string,
        lastName: string,
        avatar: string,
        eventKey: string,
        eventName: string,
        songKey: string,
        songName: string,
        songArtist: string,
        songThumb: string,
        packageName: string,
        packageId: string,
        djUid: string,
        djEmail: string,
        requestValue: string,
        requestType: string
    ) {
        const params = new HttpParams()
            .set('uid', uid)
            .set('email', email)
            .set('firstName', firstName)
            .set('lastName', lastName)
            .set('avatar', avatar)
            .set('eventKey', eventKey)
            .set('eventName', eventName)
            .set('songKey', songKey)
            .set('songName', songName)
            .set('songArtist', songArtist)
            .set('songThumb', songThumb)
            .set('packageName', packageName)
            .set('packageId', packageId)
            .set('djUid', djUid)
            .set('djEmail', djEmail)
            .set('requestValue', requestValue)
            .set('requestType', requestType);

        this.spinner.show();

        this.http
            .post(webService.userRequest, params)
            .subscribe((root: RequestedModel) => {
                this.spinner.hide();
                if (root.code === 0) {
                    this.currentRequestedKey = root.data;
                    localStorage.setItem(Constants.SETTINGS_CURRENT_REQUESTED_KEY, this.currentRequestedKey);
                    localStorage.setItem(Constants.SETTINGS_REQUEST_TYPE, requestType);
                    localStorage.setItem(Constants.SETTINGS_LATEST_REQUEST, Date.now().toString());

                    if (this.event.isTipping === "1") {
                        this.tipModalComponent.profileUrl = this.event.profileUrl;
                        this.tipModalComponent.djName = this.event.djName;
                        this.tipModalComponent.djEmail = this.event.djEmail;
                        this.tipModalComponent.updateItemIcon();
                        this.tipModalComponent.subTitle = " got your bpm request."
                        this.tipModalComponent.tipModal.show();
                    }

                    // this.afterRequestModalComponent.title = "Request Another BPM";

                    this.toastr.success('Information', this.event.djName + ' has received your bpm request.');
                } else {
                    this.toastr.error('Error', 'Something went wrong');
                }
            });
    }

    bpmChanged(value) {
        this.bpm = value;
        this.bpmTitle = this.bpm + " BPM";
        this.bpmSubtitle = this.bpmTemplate.get(Number(value));

        this.setSpeed(this.bpm / 60)
    }

    initBPMTemplate() {
        this.bpmTemplate = new Map<number, string>();
        this.bpmTemplate.set(60, "Hip-Hop, Dub, Reggae, R&B");
        this.bpmTemplate.set(65, "Hip-Hop, Dub, Reggae, R&B");
        this.bpmTemplate.set(70, "Hip-Hop, Dub, Reggae, R&B");
        this.bpmTemplate.set(75, "Hip-Hop, Dub, Reggae, R&B");
        this.bpmTemplate.set(80, "Hip-Hop, Dub, Reggae");
        this.bpmTemplate.set(85, "Hip-Hop, Dub, Reggae");
        this.bpmTemplate.set(90, "Hip-Hop, Dub, Reggae");
        this.bpmTemplate.set(95, "Hip-Hop");
        this.bpmTemplate.set(100, "Pop, Hip-Hop");
        this.bpmTemplate.set(105, "Pop, Hip-Hop");
        this.bpmTemplate.set(110, "Pop, Hip-Hop, Rock");
        this.bpmTemplate.set(115, "Pop, Hip-Hop, Rock");
        this.bpmTemplate.set(120, "Pop, House, Techno, Rock");
        this.bpmTemplate.set(125, "Pop, House, Techno, Rock");
        this.bpmTemplate.set(130, "Pop, House, Techno, Rock, Trap");
        this.bpmTemplate.set(135, "House, Techno, Dubstep, Rock, Trap");
        this.bpmTemplate.set(140, "Techno, Dubstep, Rock, Trap");
        this.bpmTemplate.set(145, "Techno, Dubstep, Trap");
        this.bpmTemplate.set(150, "Techno, Trap");
        this.bpmTemplate.set(155, "Techno, Trap");
        this.bpmTemplate.set(160, "Drum and Bass, Techno, Trap");
        this.bpmTemplate.set(165, "Drum and Bass, Trap");
        this.bpmTemplate.set(170, "Drum and Bass, Trap");
        this.bpmTemplate.set(175, "Drum and Bass, Trap");
        this.bpmTemplate.set(180, "Drum and Bass, Trap");
    }
}