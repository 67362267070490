import { Coordinates } from "../models/Coordinates";
import { Constants } from "./Constants";
import { v4 as uuidv4 } from 'uuid';

export class Helpers {

  public static msToTime(duration: any) {
    const seconds = Math.floor((duration / 1000) % 60);
    const minutes = Math.floor((duration / (1000 * 60)) % 60);

    const finalMinutes = minutes < 10 ? "0" + minutes : minutes;
    const finalSeconds = seconds < 10 ? "0" + seconds : seconds;

    return finalMinutes + ":" + finalSeconds;
  }

  public static groupBy = <T, K extends keyof any>(
    list: T[],
    getKey: (item: T) => K
  ) =>
    list.reduce((previous, currentItem) => {
      const group = getKey(currentItem);
      if (!previous[group]) previous[group] = [];
      previous[group].push(currentItem);
      return previous;
    }, {} as Record<K, T[]>);

  public static sortArrayOfObjects = <T>(
    data: T[],
    keyToSort: keyof T,
    direction: "asc" | "desc" | "none"
  ) => {
    if (direction === "none") {
      return data;
    }
    const compare = (objectA: T, objectB: T) => {
      const valueA = objectA[keyToSort];
      const valueB = objectB[keyToSort];

      if (valueA === valueB) {
        return 0;
      }

      if (valueA > valueB) {
        return direction === "asc" ? 1 : -1;
      } else {
        return direction === "asc" ? -1 : 1;
      }
    };

    return data.slice().sort(compare);
  };

  public static find(items, text) {
    text = text.toString().toLowerCase().split(' ');
    return items.filter(item => {
      return text.every(el => {
        return item.toString().toLowerCase().includes(el);
      });
    });
  }

  public static random() {
    return Math.floor(Math.random() * (1000000 - 1 + 1)) + 1;
  }

  public static getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  public static generateUUID(): string {
    var savedUUID = localStorage.getItem(
      Constants.SETTINGS_UUID
    );
    if (savedUUID === undefined || savedUUID === null) {
      let uuid = uuidv4();
      localStorage.setItem(Constants.SETTINGS_UUID, uuid);
      savedUUID = uuid;
    }

    return savedUUID;
  }

  public static saveLocation(lat: string, lng: string) {
    localStorage.setItem(Constants.SETTINGS_LAT, lat);
    localStorage.setItem(Constants.SETTINGS_LNG, lng);
  }

  public static degreesToRadians(degrees: number) {
    var radians = (degrees * Math.PI) / 180;
    return radians;
  }

  public static calcCoordsDistance(starting: Coordinates, destination: Coordinates): number {
    const startingLat = this.degreesToRadians(starting.latitude);
    const startingLong = this.degreesToRadians(starting.longitude);
    const destinationLat = this.degreesToRadians(destination.latitude);
    const destinationLong = this.degreesToRadians(destination.longitude);

    // Radius of the Earth in kilometers
    const radius: number = 6571;

    // Haversine equation
    const distanceInKilometers: number = Math.acos(Math.sin(startingLat) * Math.sin(destinationLat) +
      Math.cos(startingLat) * Math.cos(destinationLat) *
      Math.cos(startingLong - destinationLong)) * radius;

    return Math.floor(distanceInKilometers * 100) / 100;
  }
}
